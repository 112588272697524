var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "page",
      on: {
        touchstart: function ($event) {
          $event.stopPropagation()
        },
        touchmove: function ($event) {
          $event.stopPropagation()
        },
        touchend: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c("div", { staticClass: "order-top" }, [
        _c("div", { staticClass: "top-left" }, [
          _c("img", {
            attrs: { src: require("@/assets/icon_dingdan_daifukuan@2x.png") },
          }),
          _vm.order_detail.order_status == 1 &&
          _vm.order_detail.refund_status != 0 &&
          (_vm.order_detail.refund_status != 2) &
            (_vm.order_detail.refund_status != 4)
            ? _c("span", [_vm._v("待发货")])
            : _vm._e(),
          _vm.order_detail.order_status == 2 &&
          _vm.order_detail.refund_status != 0 &&
          (_vm.order_detail.refund_status != 2) &
            (_vm.order_detail.refund_status != 4)
            ? _c("span", [_vm._v("待收货")])
            : _vm._e(),
          _vm.order_detail.order_status == 3 &&
          _vm.order_detail.refund_status != 0 &&
          (_vm.order_detail.refund_status != 2) &
            (_vm.order_detail.refund_status != 4)
            ? _c("span", [_vm._v("已完成")])
            : _vm._e(),
          _vm.order_detail.refund_status == 0 ||
          _vm.order_detail.refund_status == 2 ||
          _vm.order_detail.refund_status == 4
            ? _c("span", [_vm._v("售后中")])
            : _vm._e(),
        ]),
      ]),
      _vm.order_detail &&
      _vm.order_detail.item_list &&
      _vm.order_detail.item_list[0].source_type != 6
        ? _c("div", { staticClass: "order-address" }, [
            _c("p", { staticClass: "user-info" }, [
              _c("span", { staticClass: "user-name fs-weight" }, [
                _vm._v(_vm._s(_vm.order_detail.realname)),
              ]),
              _c("span", { staticClass: "user-phone fs-weight" }, [
                _vm._v(_vm._s(_vm._f("hideMobile")(_vm.order_detail.mobile))),
              ]),
            ]),
            _c("p", { staticClass: "user-address" }, [
              _vm._v(_vm._s(_vm.order_detail.address)),
            ]),
            _c("div", { staticClass: "order-line" }),
          ])
        : _vm._e(),
      _c("div", { staticClass: "material-flow" }, [
        _c(
          "div",
          { staticClass: "goods-list" },
          _vm._l(_vm.order_detail.item_list, function (item) {
            return _c("div", { key: item.item_id, staticClass: "goods-item" }, [
              _c("div", { staticClass: "img-box" }, [
                _c("img", { attrs: { src: item.base_pic, alt: "" } }),
              ]),
              _c("div", { staticClass: "img-right" }, [
                _c("p", { staticClass: "item-name" }, [
                  _vm._v(_vm._s(item.item_name)),
                ]),
                _c("p", { staticClass: "item-name" }, [
                  _vm._v(_vm._s(item.property)),
                ]),
              ]),
              _vm.is_dzx && _vm.order_detail.user_remark
                ? _c("p", { staticClass: "user-remark" }, [
                    _vm._v("发货时间" + _vm._s(_vm.order_detail.user_remark)),
                  ])
                : _vm._e(),
              _vm.order_detail.customer_config &&
              _vm.order_detail.customer_config.telephone &&
              _vm.order_detail.customer_config.telephone_open
                ? _c(
                    "div",
                    {
                      staticClass: "service",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.show_service = true
                        },
                      },
                    },
                    [
                      _c("yd-icon", {
                        attrs: {
                          name: "phone2",
                          size: ".3rem",
                          color: "#FF685D",
                        },
                      }),
                      _c("span", { staticStyle: { color: "#FF685D" } }, [
                        _vm._v("销售热线"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          }),
          0
        ),
        _vm.order_detail.refund_express_company ||
        _vm.order_detail.refund_return_express_company
          ? _c("div", { staticClass: "material-box", attrs: { id: "tkwl" } }, [
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.order_detail.refund_express_company
                      ? `寄回物流：${_vm.order_detail.refund_express_company}  ${_vm.order_detail.refund_express_number}`
                      : ""
                  )
                ),
              ]),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.order_detail.refund_return_express_company
                      ? `新发物流：${_vm.order_detail.refund_return_express_company}  ${_vm.order_detail.refund_return_express_number}`
                      : ""
                  )
                ),
              ]),
            ])
          : _vm._e(),
        _vm.hasFlow
          ? _c(
              "div",
              { staticClass: "material-box" },
              [
                _vm.expName && _vm.number
                  ? _c("div", { staticClass: "material-title" }, [
                      _vm._v(_vm._s(_vm.expName) + "：" + _vm._s(_vm.number)),
                    ])
                  : _vm._e(),
                _vm._l(_vm.logistics_list, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "material-line" },
                    [
                      _c("div", { staticClass: "time" }, [
                        _c("p", [_vm._v(_vm._s(item.date))]),
                        _c("p", [_vm._v(_vm._s(item.minutes))]),
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _c("p", [_vm._v(_vm._s(item.status))]),
                      ]),
                    ]
                  )
                }),
              ],
              2
            )
          : _c("div", { staticClass: "flow-empty flex-center" }, [
              _vm._m(0),
              _c("div", { staticClass: "tip" }, [_vm._v("暂无物流信息")]),
            ]),
      ]),
      _c("FillLogistics", {
        attrs: { id: _vm.order_detail.refund_id, value: _vm.show_fill },
        on: {
          cancel: function ($event) {
            _vm.show_fill = false
          },
          submitFill: _vm.submitFill,
          click: function ($event) {
            $event.stopPropagation()
            _vm.show_fill = true
          },
        },
      }),
      _c("confirm-alert", {
        attrs: {
          value: _vm.show_toast,
          is_delete: true,
          confirmText: _vm.confirmText,
          confirmBtnText: "确认取消",
        },
        on: { cancel: _vm.cancel, confirm: _vm.confirm },
      }),
      _c(
        "yd-popup",
        {
          attrs: { position: "center", width: "80%" },
          model: {
            value: _vm.show_service,
            callback: function ($$v) {
              _vm.show_service = $$v
            },
            expression: "show_service",
          },
        },
        [
          _vm.order_detail.customer_config
            ? _c("div", { staticClass: "kf-box" }, [
                _c("img", {
                  attrs: { src: require("@/assets/ic_back_.png") },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.show_service = false
                    },
                  },
                }),
                _c("p", { staticClass: "kf-mobile" }, [
                  _vm._v("\n            销售热线:\n            "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.order_detail.customer_config.telephone)),
                  ]),
                ]),
                _c(
                  "p",
                  { staticClass: "btn-box" },
                  [
                    _c("span", { staticClass: "dial" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "tel:" +
                              _vm.order_detail.customer_config.telephone,
                          },
                        },
                        [_vm._v("确认呼叫")]
                      ),
                    ]),
                    _c(
                      "yd-button",
                      {
                        nativeOn: {
                          click: function ($event) {
                            _vm.show_service = false
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "bottom" }, [
        _vm.order_detail.refund_status * 1 == 2
          ? _c(
              "div",
              {
                staticClass: "btn collect-btn flex-center",
                on: { click: _vm.openwuliu },
              },
              [_vm._v("填写物流")]
            )
          : _vm._e(),
        _vm.order_detail.refund_status == 0 ||
        _vm.order_detail.refund_status == 2
          ? _c(
              "div",
              {
                staticClass: "btn collect-btn flex-center",
                on: { click: _vm.cancelApply },
              },
              [_vm._v("取消申请")]
            )
          : _vm._e(),
        _vm.order_detail.order_status == 2 &&
        _vm.order_detail.refund_status * 1 != 0 &&
        _vm.order_detail.refund_status * 1 != 2 &&
        _vm.order_detail.refund_status * 1 != 4 &&
        _vm.order_detail.refund_status * 1 != 7
          ? _c(
              "div",
              {
                staticClass: "btn collect-btn flex-center",
                on: { click: _vm.applySale },
              },
              [_vm._v("申请售后")]
            )
          : _vm._e(),
        _vm.order_detail.order_status == 2 &&
        _vm.order_detail.refund_status * 1 != 0 &&
        _vm.order_detail.refund_status * 1 != 2 &&
        _vm.order_detail.refund_status * 1 != 4
          ? _c(
              "div",
              {
                staticClass: "btn collect-btn flex-center",
                on: { click: _vm.confirmReceipt },
              },
              [_vm._v("确认收货")]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "btn collect-btn flex-center",
            on: { click: _vm.openChat },
          },
          [_vm._v("联系客服")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img-box" }, [
      _c("img", {
        attrs: { src: require("@/assets/img_location_blank@2x.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }