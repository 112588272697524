/*
 * @Author: zwb
 * @Date: 2019-05-28
 * @Last Modified by: zwb
 * @Last Modified time: 2019-05-28
 */
import fetch from "../utils/fetch";

/**
 * 提交订单（购物车和直接下单）
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const checkItem = params =>
  fetch("/api", {
    api_name: "V1.item.item.checkItem",
    ...params
  });
/**
 * 提交订单
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const createOrder = params =>
  fetch("/api", {
    api_name: "V1.Order.Order.createOrder",
    ...params
  });
/**
 * 提交订单检测购物车商品，返回不可下单的商品列表
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const checkCartItems = params =>
  fetch("/api",{
    api_name:"V1.Order.Order.checkCartItems",
    ...params
  });
/**
 * 确认订单（购物车和商品详情页面进入）
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const confirmOrder = params =>
  fetch("/api", {
    api_name: "V1.Order.Order.confirmOrder",
    ...params
  });
/**
 * 订单支付
 * @param pay_order_id
 * @returns {Promise<*|*|undefined>}
 */
/* export const payOrder = (pay_order_id, password) =>
  fetch("/api", {
    api_name: "V1.Order.Order.pay",
    pay_order_id: pay_order_id,
    password: password
  }); */
	export const payOrder = params =>
	  fetch("/api", {
	    api_name: "V1.Order.Order.pay",
	    ...params
	  });
export const cardPayOrder = params =>
  fetch("/api", {
    api_name: "V1.Order.Order.pay",
    ...params
  });
/**
 * 购物车列表
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const getShopCartList = params =>
  fetch("/api", {
    api_name: "V1.Order.ShopCart.getShopCartList",
    ...params
  });
/**
 * 购物车加减
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const changeCartNum = params =>
  fetch("/api", {
    api_name: "V1.Order.ShopCart.changeCartNum",
    ...params
  });
/**
 * 删除购物车商品数据
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const cancelShopCarts = shop_cart_ids =>
  fetch("/api", {
    api_name: "V1.Order.ShopCart.cancelShopCarts",
    shop_cart_ids: shop_cart_ids
  });
// 订单列表
export const getOrderList = (page, listRows, order_status, business_type) =>
  fetch("/api", {
    api_name: "V1.Order.Order.getOrderListPayed",
    page: page,
    listRows: listRows,
    order_status: order_status,
    business_type: business_type
  });
// 售后列表
export const getAfterSaleList = (page, listRows, order_status) =>
  fetch("/api", {
    api_name: "V1.Order.ItemRefund.index",
    page: page,
    listRows: listRows,
    status: order_status
  });

// 订单汇总详情
export const orderCollectDetail = pay_order_id =>
  fetch("/api", {
    api_name: "V1.Order.Order.getPayOrderInfo",
    pay_order_id: pay_order_id
  });
// 订单详情
export const orderDetail = params =>
  fetch("/api", {
    api_name: "V1.Order.Order.getOrderInfo",
    ...params
  });
// 海元素订单详情
export const getExchangeInfo = (order_id, pay_order_id) =>
fetch("/api", {
  api_name: "V1.Order.Order.getExchangeInfo",
  order_id: order_id,
  pay_order_id: pay_order_id
});
/**
 * 获取物流信息
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const getLogisticsInfo = params =>
  fetch("/api", {
    api_name: "V1.Order.Order.getLogisticsInfo",
    ...params
  });
/**
 * 申请售后填写物流信息
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const editRefund = params =>
  fetch("/api", {
    api_name: "V1.Order.ItemRefund.editRefund",
    ...params
  });
/**
 * 获取物流公司
 * @returns {Promise<*|*|undefined>}
 */
export const shippingCompany = () =>
  fetch("/api", {
    api_name: "V1.Order.ItemRefund.shippingCompany",
  });
/**
 * 确认收货
 * @param order_id
 * @returns {Promise<*|*|undefined>}
 */
/* export const confirmReceipt = order_id =>
  fetch("/api", {
    api_name: "V1.Order.Order.confirmReceipt",
    order_id: order_id
  }); */
	export const confirmReceipt = params =>
	  fetch("/api", {
	    api_name: "V1.Order.Order.confirmReceipt",
	    ...params
	  });
/**
 * 评价订单
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const evaluateOrder = params =>
  fetch("/api", {
    api_name: "V1.item.evaluate.evaluate",
    ...params
  });
// 取消订单
/* export const cancelOrder = pay_order_id =>
  fetch("/api", {
    api_name: "V1.Order.Order.cancelOrder",
    pay_order_id: pay_order_id
  }); */
export const cancelOrder = params =>
  fetch("/api", {
    api_name: "V1.Order.Order.cancelOrder",
    ...params
  });
// 获取退款原因
export const getRefundReason = (type) =>
  fetch("/api", {
    api_name: "V1.Order.ItemRefund.getReason",
    type: type

  });
// 申请售后
export const applySale = params =>
  fetch("/api", {
    api_name: "V1.Order.ItemRefund.add",
    ...params
  });
// 售后详情
export const afterSaleDetail = (id, order_status) =>
  fetch("/api", {
    api_name: "V1.Order.ItemRefund.refundDetail",
    id: id,
    order_status: order_status
  });
// 取消售后
/* export const cancelAfterSale = id =>
  fetch("/api", {
    api_name: "V1.Order.ItemRefund.cancel",
    id: id
  }); */
export const cancelAfterSale = params =>
  fetch("/api", {
    api_name: "V1.Order.ItemRefund.cancel",
    ...params
  });
/**
 * 删除订单
 * @param id
 * @returns {Promise<*|*|undefined>}
 */
export const delOrder = id =>
  fetch("/api", {
    api_name: "V1.Order.Order.delOrder",
    order_id: id
  });
//评价订单详情
export const getEvaluateInfo = id =>
  fetch("/api", {
    api_name: "V1.item.evaluate.getEvaluateInfo",
    order_id: id
  });
/**
 * 满减活动
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const getReductionList = params =>
  fetch("/api", {
    api_name: "V1.Order.Order.confirmMarketing",
    ...params
  });

/**
 * 获取当前内采用户的预算类型
 * @param params
 * @returns {Promise<*|*|undefined>}
 */
export const getBudgetType = params =>
  fetch("/api", {
    api_name: "V1.Order.Order.getBudgetType"
  });

/**
 * 中行获取订单信息（支付收银台）
 * @param id
 * @returns {Promise<*|*|undefined>}
 */
export const getPayInfo = id =>
  fetch("/api", {
    api_name: "V1.service.BocPayApi.getPayInfo",
    order_sn: id
  });