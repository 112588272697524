<template>
  <div class="alert-container" v-show="show" @touchend.self.stop>
    <div class="alert-wrapper" ref="wrapper" @touchend.stop>
      <header>
        <h4
          :class="ischeck ? 'titlecheck title' : 'title'"
          @click="searchOrderBtn(1)"
        >
          {{this.value.type==1?"卡号兑换":"卡密查询"}}
        </h4>
        <h4
          :class="ischeck ? 'title' : 'title titlecheck'"
          @click="searchOrderBtn(2)"
          v-if="value.phone"
        >
          {{this.value.type==1?'手机号兑换':"收件手机查询"}}
        </h4>
      </header>
      <main>
        <div class="form">
          <div class="form-item">
            <div class="input-box">
              <input
                class="input"
                v-model="card_sn"
                type="text"
                :placeholder="ischeck ? '请输入卡号(字母区分大小写)' : '请输入手机号'"
                @touchend.stop
                @focus="scrollView"
                @blur="scrollBack"
              />
            </div>
          </div>
          <div class="form-item" v-if="!ischeck&&this.value.type!==1">
            <div class="inputboxsms">
              <input
                :class="ischeck ? 'input' : 'inputSms'"
                v-model="imgtext"
                type="text"
                placeholder="请输入图形验证码"
                @touchend.stop
                @focus="scrollView"
                @blur="scrollBack"
              />
            </div>
            <img :src="img_code" class="button" @click="getRandomImgCode" />
            <!-- <button class="button" v-if="!ischeck">获取短信验证码</button> -->
          </div>
          <div class="form-item">
            <div :class="ischeck ? 'input-box' : 'inputboxsms'">
              <input
                :class="ischeck ? 'input' : 'inputSms'"
                v-model="card_pass"
                type="text"
                :placeholder="ischeck ? '请输入密码' : '短信验证码'"
                @touchend.stop
                @focus="scrollView"
                @blur="scrollBack"
              />
            </div>
            <button
              class="button btnsms"
              @click="getSmsCode"
              v-if="!ischeck"
              v-show="code_bool"
            >
              获取短信验证码
            </button>
            <p class="button" v-show="!code_bool">{{ count }}s</p>
          </div>
        </div>
        <!-- S 弹窗提示 -->
        <alert-tip
          :type="type"
          :showAlert="show_tip"
          :alertText="tip"
          @changeShowAlert="changeShow"
        ></alert-tip>
        <!-- E 弹窗提示 -->
      </main>
      <footer>
        <div class="btn submit-btn flex-center" @touchend.stop="submit">
          提交
        </div>
      </footer>
      <div class="close-btn-wrap flex-center" @touchend.stop="close">
        <span class="close-btn"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { getImgCode, sendVerifyCode, getColor, logout,kaceSendsms } from "@/services";
import { debounce } from "@/utils/debounce";
import Validator from "@/utils/validator";
import AlertTip from "@/components/common/AlertTip";
import { toolTip } from "@/mixins";
import { getUrlKey} from "../../utils/common";
export default {
  name: "CheckCard",
  mixins: [toolTip],
  data() {
    return {
      show: false,
      card_sn: "", // 卡号
      card_pass: "", // 密码
      ischeck: true, //切换卡密和手机号查询显示隐藏参数
      img_code: "", //图片验证码的图
      code_bool: true, // 控制显示获取验证码
      count: "", // 倒计时
      timer: null, // 计时器
      imgtext: "", //图片验证码
      smscode: "" //短信验证码
    };
  },
  components: {
    AlertTip
  },
  props: {
    value: {
      // type: Boolean,
      // default: false
    }
  },
  mounted() {
    console.log(this.value.type,344)
    this.loadImgCode();
    var originalHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    var h = document.body.scrollHeight;
    console.log({ originalHeight }, { h });
    var that = this;
    window.onresize = function() {
      var resizeHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      console.log(11111, resizeHeight, originalHeight);
      if (resizeHeight < originalHeight) {
        //证明窗口被挤压了
        that.scrollView();
      } else {
        that.scrollBack();
      }
    };
    // window.οnresize = function() {
    //   console.log(1111);
    //   //软键盘弹起与隐藏  都会引起窗口的高度发生变化
    //   var resizeHeight =
    //     document.documentElement.clientHeight || document.body.clientHeight;
    //   console.log("软键盘弹起后窗口的高度"+resizeHeight);
    //   if (resizeHeight < originalHeight) {
    //     //证明窗口被挤压了
    //     that.scrollView();
    //   } else {
    //     that.scrollBack();
    //   }
    // };
  },
  methods: {
    // 获取短信验证码
    getSmsCode: debounce(async function() {
      const card_book_id = getUrlKey("card_book_id");
      let mobile = this.card_sn.replace(/\s+/g, ""),
        code = this.imgtext.replace(/\s+/g, "");
      const { result, errorMessage } = Validator.validate("mobile", mobile);
      if (!mobile) {
        this.show_layer("cancel", "请输入手机号码");
        return;
      }
      if (!result) {
        this.show_layer("cancel", errorMessage);
        return;
      }
      if (!code&&this.value.type!==1) {
        this.show_layer("cancel", "请输入图形验证码");
        return;
      }
      if (this.timer) {
        clearInterval(this.timer);
      }
      let res;
      if(this.value.type==1){
         res = await kaceSendsms(mobile, card_book_id);
      }else{
          res = await sendVerifyCode(mobile, "register", code);
      }
      if (res.code === 0) {
        this.getRandomImgCode();
        this.$dialog.toast({
          mes: "验证码已发送，请注意查收"
        });
        this.count = 60;
        this.code_bool = false;
        this.timer = setInterval(() => {
          if (this.count > 0) {
            this.count--;
          } else {
            this.code_bool = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      } else {
        this.$dialog.toast({
          mes: res.msg
        });
      }
    }, 300),
    // 获取图形验证码
    async loadImgCode() {
      const res = await getImgCode(this.generalize_id);
      if (res.code === 0) {
        this.img_code = res.data.url;
        // base_code = res.data.url;
      } else {
        if (process.env.NODE_ENV === "development") {
          this.$dialog.toast({
            mes: res.error_msg
          });
        }
      }
    },
    // 切换图形验证码
    getRandomImgCode: debounce(function() {
      this.img_code = this.img_code + "&" + Math.random();
    }, 300),
    searchOrderBtn(type) {
      //查询订单方式切换按钮事件
      this.ischeck = type == 1 ? true : false;
    },
    // 点击提交按钮
    submit() {
      if (this.ischeck) {
        let card_sn = this.card_sn.replace(/\s+/, ""),
          card_pass = this.card_pass.replace(/\s+/, "");
        // console.log({ card_sn });
        if (!card_sn) {
          this.$dialog.toast({
            mes: "请输入卡号"
          });
          return;
        }
        if (!card_pass) {
          this.$dialog.toast({
            mes: "请输入密码"
          });
          return;
        }
        this.$emit("handleSubmit", card_sn, card_pass);
      } else {
        let mobile = this.card_sn.replace(/\s+/g, ""),
          code = this.imgtext.replace(/\s+/g, "");
        const { result, errorMessage } = Validator.validate("mobile", mobile);
        if (!mobile) {
          this.show_layer("cancel", "请输入手机号码");
          return;
        }
        if (!result) {
          this.show_layer("cancel", errorMessage);
          return;
        }
        if (!code&&this.value.type!==1) {
          this.show_layer("cancel", "请输入图形验证码");
          alert(this.value.type)
          return;
        }
        if(!this.card_pass){
          this.show_layer("cancel", "请输入短信验证码");
          return;
        }
          
          if(this.value.type==1){
              this.$emit("handleSubmit", mobile, this.card_pass,2);
          }else{
            this.$emit("handleSubmit", mobile, this.card_pass,2);
          }
          

      }
    },
    // 点击关闭
    close() {
      this.$emit("handleClose");
    },
    scrollView() {
      setTimeout(() => {
        window.scroll(0, 0);
        document.body.scrollTop = 0;
        this.$refs.wrapper.style.top = 0;
      }, 200);
    },
    scrollBack() {
      setTimeout(() => {
        window.scroll(0, 0);
        document.body.scrollTop = 0;
        let rootFontSize = document.documentElement.style.fontSize.split(
          "px"
        )[0];
        this.$refs.wrapper.style.top = rootFontSize * 3.12 + "px";
      }, 200);
    }
  },
  watch: {
    value: {
      handler: function(val) {
        this.show = val.card;
      },
      immediate: true
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.alert-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  .alert-wrapper {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 3.12rem;
    // .wh(6rem, 5.28rem);
    width: 6rem;
    padding: 0.64rem 0.36rem 0.3rem;
    background-color: #fff;
    border-radius: 0.08rem;
    box-sizing: border-box;
    header {
      margin-bottom: 0.3rem;
      display: flex;
      // justify-content: center;
      .title {
        .sc(@fontsize-large-x, @color-dark-grey);
        font-size:0.3rem;
        width:40%;
        text-align:center;
        margin-left:5%;
        // float: left;
        // line-height: 0.5rem;
        // text-align: center;
        // white-space: nowrap;
        // margin-left: 0.5rem;
        padding: 0.1rem;
      }
      .titlecheck {
        color: coral;
      }
    }
    main {
      .button {
        float: right;
        height: 100%;
        width: 45%;
        border: 0;
        text-align: center;
        line-height: 3;
      }
      .btnsms{
        background:#ff4c4c;
        color:#fff;
        border-radius:0.1rem;
        font-size:0.3rem;
      }
      margin-bottom: 0.48rem;
      .form {
        .form-item {
          .wh(100%, 0.88rem);
          background-color: #f7f7f7;
          margin-bottom: 0.24rem;
          border-radius: 0.08rem;
          box-sizing: border-box;
          .inputboxsms {
            float: left;
            width: 50%;
            padding: 0.22rem 0.24rem;
            box-sizing: border-box;
          }
          .input-box {
            padding: 0.22rem 0.24rem;
            box-sizing: border-box;
            .input {
              display: block;
              .wh(100%, 100%);
              .sc(@fontsize-large, @color-dark-grey);
              line-height: 0.44rem;
              border: none;
              outline: none;
            }
            .inputSms {
              display: block;
              .wh(50%, 100%);
              .sc(@fontsize-large, @color-dark-grey);
              line-height: 0.44rem;
              border: none;
              outline: none;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    footer {
      .submit-btn {
        .wh(100%, 0.88rem);
        .sc(@fontsize-large, @color-white);
        line-height: 0.44rem;
        text-align: center;
        white-space: nowrap;
        background-color: #ff4c4c;
        border-radius: 0.08rem;
      }
    }
    .close-btn-wrap {
      position: absolute;
      top: 0;
      right: 0;
      .wh(0.88rem, 0.88rem);
    }
    .close-btn {
      width: 0.2em;
      height: 1em;
      background: #666;
      transform: rotate(45deg);
      display: inline-block;
      cursor: pointer;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0.2em;
        height: 1em;
        background: #666;
        transform: rotate(270deg);
      }
    }
  }
}
</style>
