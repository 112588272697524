<template>
  <div class="page">
    <div class="img-box">
      <img src="~@/static/image/ic_xuanze_big.png" alt="">
    </div>
    <p class="tip">您的商品已兑换成功</p>
    <router-link to="check_order" class="gray-tip" replace="">查看订单>></router-link>
  </div>
</template>

<script>
export default {
  name: 'ExchangeSuccess'
}
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.page {
  .img-box {
    .wh(2.2rem, 2.2rem);
    margin: .8rem auto .84rem;
    img {
      .wh(100%, 100%);
    }
  }
  .tip {
    margin-bottom: .16rem;
    .sc(@fontsize-large, @color-dark-grey);
    line-height: .44rem;
    text-align: center;
  }
  .gray-tip {
    display: block;
    .sc(@fontsize-medium, @color-grey);
    line-height: .4rem;
    text-align: center;
  }
}
</style>