<template>
  <div class="alert-container" v-if="showAlert">
    <section class="tip-text-container flex-center">
      <img :src="iconTip" alt="icon" class="tip-icon">
      <p class="tip-text">{{alertText}}</p>
    </section>
  </div>
</template>

<script>
import iconSuccess from '@/assets/icon_right_white@2x.png';
import iconCancel from '@/assets/icon_close_white@2x.png';

const map = new Map([
  ['success', iconSuccess],
  ['cancel', iconCancel]
]);
export default {
  name: 'AlertTip',
  data() {
    return {
      myShowAlert: this.showAlert
    }
  },
  props: {
    'type': {type: String, default: 'cancel'},
    'showAlert': {type: Boolean, default: false},
    'alertText': {type: String, default: ''},
    'computedTime': {type: Number, default: 3000}
  },
  computed: {
    iconTip() {
      return map.get(this.type);
    }
  },
  watch: {
    showAlert(val) {
      this.myShowAlert = val;
      this.hideAlert();
    },
    myShowAlert(val) {
      this.$emit('changeShowAlert', val);
    }
  },
  methods: {
    hideAlert() {
      if (this.myShowAlert) {
        setTimeout(() => {
          this.myShowAlert = false;
        }, this.computedTime);
      }
    }
  }
}
</script>

<style scoped>
@keyframes tipMove {
  0% {
    transform: scale(1)
  }
  35% {
    transform: scale(.8)
  }
  70% {
    transform: scale(1.1)
  }
  100% {
    transform: scale(1)
  }
}

.alert-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
}

.tip-text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  flex-direction: column;
  margin-top: -1.0rem;
  margin-left: -2.0rem;
  width: 4.00rem;
  height: 2.00rem;
  box-sizing: border-box;
  background: #0C0C0C;
  animation: tipMove .4s;
  border-radius: 4px;
  opacity: .8;
}

.tip-text-container .tip-icon {
  width: .48rem;
  height: .48rem;
  margin: 0 auto .36rem;
}

.tip-text-container .tip-text {
  font-size: .28rem;
  color: #fff;
  line-height: .48rem;
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
}
</style>
