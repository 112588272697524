var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "alert-container",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          if ($event.target !== $event.currentTarget) return null
          return _vm.cancel.apply(null, arguments)
        },
        scroll: function ($event) {
          $event.preventDefault()
        },
        touchmove: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c(
        "section",
        { staticClass: "confirm-container" },
        [
          _c("P", {
            staticClass: "confirm-text",
            domProps: { innerHTML: _vm._s(_vm.confirmText) },
          }),
          _c("div", { staticClass: "btn-group" }, [
            _c(
              "div",
              {
                staticClass: "btn cancel-btn flex-center",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.cancel.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.cancelBtnText))]
            ),
            !_vm.is_delete
              ? _c(
                  "div",
                  {
                    staticClass: "btn confirm-btn flex-center",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.confirm.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.confirmBtnText))]
                )
              : _vm._e(),
            _vm.is_delete
              ? _c(
                  "div",
                  {
                    staticClass: "btn delete-btn flex-center",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.confirm.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.confirmBtnText))]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }