var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "alert-container",
      on: {
        touchend: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "div",
        {
          ref: "wrapper",
          staticClass: "alert-wrapper",
          on: {
            touchend: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c("header", [
            _c(
              "h4",
              {
                class: _vm.ischeck ? "titlecheck title" : "title",
                on: {
                  click: function ($event) {
                    return _vm.searchOrderBtn(1)
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(this.value.type == 1 ? "卡号兑换" : "卡密查询") +
                    "\n      "
                ),
              ]
            ),
            _vm.value.phone
              ? _c(
                  "h4",
                  {
                    class: _vm.ischeck ? "title" : "title titlecheck",
                    on: {
                      click: function ($event) {
                        return _vm.searchOrderBtn(2)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          this.value.type == 1 ? "手机号兑换" : "收件手机查询"
                        ) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _c(
            "main",
            [
              _c("div", { staticClass: "form" }, [
                _c("div", { staticClass: "form-item" }, [
                  _c("div", { staticClass: "input-box" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.card_sn,
                          expression: "card_sn",
                        },
                      ],
                      staticClass: "input",
                      attrs: {
                        type: "text",
                        placeholder: _vm.ischeck
                          ? "请输入卡号(字母区分大小写)"
                          : "请输入手机号",
                      },
                      domProps: { value: _vm.card_sn },
                      on: {
                        touchend: function ($event) {
                          $event.stopPropagation()
                        },
                        focus: _vm.scrollView,
                        blur: _vm.scrollBack,
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.card_sn = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                !_vm.ischeck && this.value.type !== 1
                  ? _c("div", { staticClass: "form-item" }, [
                      _c("div", { staticClass: "inputboxsms" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.imgtext,
                              expression: "imgtext",
                            },
                          ],
                          class: _vm.ischeck ? "input" : "inputSms",
                          attrs: {
                            type: "text",
                            placeholder: "请输入图形验证码",
                          },
                          domProps: { value: _vm.imgtext },
                          on: {
                            touchend: function ($event) {
                              $event.stopPropagation()
                            },
                            focus: _vm.scrollView,
                            blur: _vm.scrollBack,
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.imgtext = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _c("img", {
                        staticClass: "button",
                        attrs: { src: _vm.img_code },
                        on: { click: _vm.getRandomImgCode },
                      }),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form-item" }, [
                  _c(
                    "div",
                    { class: _vm.ischeck ? "input-box" : "inputboxsms" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.card_pass,
                            expression: "card_pass",
                          },
                        ],
                        class: _vm.ischeck ? "input" : "inputSms",
                        attrs: {
                          type: "text",
                          placeholder: _vm.ischeck
                            ? "请输入密码"
                            : "短信验证码",
                        },
                        domProps: { value: _vm.card_pass },
                        on: {
                          touchend: function ($event) {
                            $event.stopPropagation()
                          },
                          focus: _vm.scrollView,
                          blur: _vm.scrollBack,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.card_pass = $event.target.value
                          },
                        },
                      }),
                    ]
                  ),
                  !_vm.ischeck
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.code_bool,
                              expression: "code_bool",
                            },
                          ],
                          staticClass: "button btnsms",
                          on: { click: _vm.getSmsCode },
                        },
                        [_vm._v("\n            获取短信验证码\n          ")]
                      )
                    : _vm._e(),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.code_bool,
                          expression: "!code_bool",
                        },
                      ],
                      staticClass: "button",
                    },
                    [_vm._v(_vm._s(_vm.count) + "s")]
                  ),
                ]),
              ]),
              _c("alert-tip", {
                attrs: {
                  type: _vm.type,
                  showAlert: _vm.show_tip,
                  alertText: _vm.tip,
                },
                on: { changeShowAlert: _vm.changeShow },
              }),
            ],
            1
          ),
          _c("footer", [
            _c(
              "div",
              {
                staticClass: "btn submit-btn flex-center",
                on: {
                  touchend: function ($event) {
                    $event.stopPropagation()
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n        提交\n      ")]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "close-btn-wrap flex-center",
              on: {
                touchend: function ($event) {
                  $event.stopPropagation()
                  return _vm.close.apply(null, arguments)
                },
              },
            },
            [_c("span", { staticClass: "close-btn" })]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }