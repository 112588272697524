<template>
  <div class="empty flex-center">
    <!-- S 空白图标 -->
    <div class="img-box">
      <div class="empty-img">
        <img :src="icon" alt="">
      </div>
    </div>
    <!-- E 空白图标 -->
    <!-- S 空白提示 -->
    <p class="empty-tip">{{tip}}</p>
    <!-- E 空白提示 -->
  </div>
</template>

<script>
export default {
  name: "empty",
  props: {
    tip: {
      type: String,
      default: "暂无更多数据哦～"
    },
    icon: {
      type: String,
      default: require('@/assets/icon_kong_dingdan@2x.png')
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.empty {
  .center;
  z-index: 2;
  flex-direction: column;

  .img-box {
    .wh(3.64rem, 2.22rem);
    margin-bottom: .74rem;

    .empty-img {
      .wh(100%, 100%);

      img {
        .wh(3.64rem, 2.22rem);
      }
    }
  }

  .empty-tip {
    max-width: 100%;
    .sc(@fontsize-medium, #909090);
    line-height: 0.4rem;
    text-align: center;
    white-space: nowrap;
  }
}
</style>
