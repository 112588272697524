var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "page",
      on: {
        touchstart: function ($event) {
          $event.stopPropagation()
        },
        touchmove: function ($event) {
          $event.stopPropagation()
        },
        touchend: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      !_vm.customAccount("VUE_APP_ZHONGHANG")
        ? _c(
            "div",
            {
              staticClass: "home-icon",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.goBack()
                },
              },
            },
            [
              _c("img", {
                attrs: { src: require("@/assets/ic_back_28.png"), alt: "" },
              }),
            ]
          )
        : _vm._e(),
      !_vm.customAccount("VUE_APP_ZHONGHANG")
        ? _c(
            "div",
            {
              staticClass: "collect-icon",
              on: {
                click: function ($event) {
                  return _vm.backHome()
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/icon_shangpin_home@2x.png"),
                  alt: "",
                },
              }),
            ]
          )
        : _vm._e(),
      _c("div", {
        staticClass: "content",
        domProps: { innerHTML: _vm._s(_vm.content) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }