var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "alert-container",
      on: {
        touchend: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          $event.stopPropagation()
          return _vm.clickMask.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "alert-wrapper" }, [
        _c(
          "main",
          {
            on: {
              touchstart: function ($event) {
                $event.stopPropagation()
              },
              touchmove: function ($event) {
                $event.stopPropagation()
              },
              touchend: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "item-list" },
              _vm._l(_vm.list, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass: "item",
                    on: {
                      touchend: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "img-box" }, [
                      _c("img", { attrs: { src: item.base_pic, alt: "" } }),
                    ]),
                    _c("div", { staticClass: "img-right" }, [
                      _c("p", { staticClass: "item-name" }, [
                        _vm._v(_vm._s(item.item_name)),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "close-btn-wrap flex-center",
                        on: {
                          touchend: function ($event) {
                            $event.stopPropagation()
                            return _vm.removeSelect(item.id)
                          },
                        },
                      },
                      [_c("span", { staticClass: "close-btn" })]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }