<template>
  <div id="pureFullPage" v-cloak>
    <div
      class="page"
      v-for="(item, index) in img_list"
      :key="index"
      :style="{backgroundImage: 'url('+item.img+')'}"
    >
      <!-- <img :src="item.img" alt /> -->
      <div class="up-tip">
        <img class="icon icon-uparrow" src="~@/assets/img_uparrow.png" alt />
        <span class="tip">上拉翻页</span>
      </div>
      <div
        class="music-wrap"
        @touchend.stop="handlePlay"
        v-if="config.music"
      >
        <img src="~@/assets/icon_music.png" alt />
      </div>
      <div
        v-if="config.video && ((config.video_index == 1 && index == 0) || (config.video_index == 2 && index == img_list.length - 1))"
        class="icon-size"
        @touchend.stop="handleVideoPlay"
        id="icon"
      >
         <img src="~@/assets/icon_play.png" alt/>
        <!-- <yd-icon name="play" size="1.5rem"></yd-icon> -->
      </div>
    </div>
    <div class="page" ref="scrollDom" v-if="item_list.length">
      <header>
        <!-- <router-link class="rule" to="exchange_rules" @touchend.stop>查看《卡册商城兑换规则》</router-link> -->
        <div class="rule" @click="checkRule()">查看《商城兑换规则》</div>
        <div class="kf-box" @click="getService()" v-if="is_dzx">
           <img src="../../assets/kf.png" alt/>
           <span>客服</span>
        </div>
        <div class="btn check-order-btn" @touchend.stop="openCheckCard(1)">
          <span class="txt">查询订单</span>
          <img class="icon icon-rightarrow" src="~@/assets/icon_redarrow.png" alt />
        </div>
      </header>
      <main>
        <div class="six-item-list" v-if="Number(listRows) === 6">
          <div
            class="item"
            :class="{active: selectedIds.indexOf(item.id) !== -1}"
            v-for="item in item_list"
            :key="item.id"
          >
          <img src="@/assets/2sq.png" class="sq-img1" v-if="!item.stock"/>
          <div class="no-stock" v-if="!item.stock">
              <div class="img-box"
                >
                  <img :src="item.base_pic" alt />
                </div>
                <div class="img-bottom">
                  <p class="item-name">{{item.item_name}}</p>
                </div>
            </div>
            <div v-else>
              <div class="img-box" @touchstart.stop="handleTouchstart" @touchend.stop="handleTouchend($event, item)">
                <img :src="item.base_pic" alt />
              </div>
              <div class="img-bottom" @touchstart.stop="handleTouchstart" @touchend.stop="toDetail(item)">
                <p class="item-name">{{item.item_name}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="four-item-list" v-if="Number(listRows) === 4">
          <!-- 库存不足商品 -->
           <div
            class="item"
            :class="{active: selectedIds.indexOf(item.id) !== -1}"
            v-for="item in item_list"
            :key="item.id"
          >
          <img src="@/assets/2sq.png" class="sq-img1" v-if="!item.stock"/>
          <div class="no-stock" v-if="!item.stock">
              <div class="img-box"
                >
                  <img :src="item.base_pic" alt />
                </div>
                <div class="img-bottom">
                  <p class="item-name">{{item.item_name}}</p>
                </div>
          </div>
          <div v-else>
             <div class="img-box"
              @touchstart.stop="handleTouchstart"
              @touchend.stop="handleTouchend($event, item)"
              >
                <img :src="item.base_pic" alt />
              </div>
              <div class="img-bottom" @touchstart.stop="handleTouchstart" @touchend.stop="toDetail(item)">
                <p class="item-name">{{item.item_name}}</p>
              </div>
          </div>
          </div>
        </div>
        <div class="pagination">
          <div class="btn prev-btn" @touchend.stop="handlePrevPage">上一页</div>
          <div class="current-page">{{current_page}}/{{total_page}}</div>
          <div class="btn next-btn" @touchend.stop="handleNextPage">下一页</div>
        </div>
      </main>
      <footer>
        <span class="selected">
          已选
          <span class="num">{{selectedIds.length}}/{{limit_num}}</span>件
        </span>
        <div class="btn view-details-btn" :class="{active: isViewed}" @touchend.stop="checkDetails">
          <span class="txt">查看明细</span>
          <img src="~@/assets/icon_uparrow.png" alt class="icon icon-arrow" />
        </div>
        <div class="btn submit-btn flex-center" @touchend.stop="openCheckCard(2)">选好了</div>
      </footer>

      <!-- S 校验卡密 -->
      <check-card
        ref="cardAlert"
       :value="showCheckCard"
        @handleSubmit="checkCard"
        @handleClose="closeCheckCard"
        v-if="showCheckCard.card"
      ></check-card>
      <!-- E 校验卡密 -->

      <selected-details v-model="isViewed" :list="selectedItems" @handleRemove="removeSelected" @clickMask="isViewed = false"></selected-details>
    </div>

    <audio ref="audio" :src="config.music" preload hidden>亲 您的浏览器不支持html5的audio标签</audio>
  </div>
</template>

<script>
// import "pure-full-page/lib/pureFullPage.min.css";
// import PureFullPage from "pure-full-page";
import "@/static/js/pureFullPage/pureFullPage.min.css";
import PureFullPage from "@/static/js/pureFullPage/pureFullPage.js";
import { getOrderList } from "@/services/cardMallApi";
import {verifyMobile} from "@/services/my"
import {
  getConfig,
  getImgs,
  getItemListCard,
  checkTicket,
  checkItemCredit,
  checkTicketByMobile
} from "@/services/cardMallApi";
import "@/utils/qiyu";
import { setStore } from "@/utils/common";
import { mapMutations, mapGetters, mapState} from "vuex";

const path = require("path");
const files = require.context("@/components/cardMall", false, /\.vue$/);
const modules = {};
files.keys().forEach(key => {
  const name = path.basename(key, ".vue");
  modules[name] = files(key).default || files(key);
});

// document.addEventListener(
//   "touchmove",
//   function(e) {
//     e.preventDefault();
//   },
//   { passive: false }
// );

/**
 * 事件委托方法
 * @param eventName {string}：事件名称，如'click'
 * @param sub {string}: 子节点选择器，如'.sub-item'
 * @param fn {function}: 事件回调方法
 * @eg var ctn = document.querySelector('#menu_container');
 *         ctn.delegate('click','.sub-item',function(event){ console.log('clicked ...'); });
 **/
Node.prototype.delegate = function(eventName, sub, fn) { // DOM 继承于 Node (原型链顶端是 Object)
  var _sef = this;
  this.addEventListener(eventName, function(event) {
    var target = event.target || event.srcElement, // srcElement 对于生成事件的 Window 对象、Document 对象或 Element 对象的引用
      list = _sef.querySelectorAll(sub); 
    if (list && Array.from(list).includes(target)) {
      if (typeof fn == 'function') fn.apply(target, arguments);
    }
  }, false);
}

// var container = document.querySelector('body');
// container.delegate('touchend', '.yd-dialog-white-mask', function(e) {
//   e.preventDefault();
//   e.stopPropagation();
// });

var fullPageInst;

function createVideo(video_url) {
  let video = document.createElement("VIDEO");
  video.setAttribute("controls", "controls");
  video.setAttribute("src", video_url);
  video.setAttribute('webkit-playsinline', "true");
  video.setAttribute('playsinline', "true");
  video.setAttribute('x5-playsinline', "true");
  video.setAttribute("hidden", "hidden");
  video.setAttribute("id", "video");

  video.addEventListener("pause", function() {
    video.setAttribute("hidden", "hidden");
    let icon = document.querySelector('#icon');
    icon.removeAttribute('style', 'display: none');
  });

  video.addEventListener("play", function() {
    video.removeAttribute('hidden')
    let icon = document.querySelector('#icon');
    icon.setAttribute('style', 'display: none');
  });

  video.addEventListener("ended", function() {
    let icon = document.querySelector('#icon');
    icon.removeAttribute('style', 'display: none');
    video.setAttribute("hidden", "hidden");
  });

  return video;
}

export default {
  name: "CardMall",
  components: modules,
  data() {
    return {
      bodyHeight: 0, // 屏幕高度
      config: {}, // 卡册商城配置
      img_list: [], // 图片列表
      item_list: [], // 商品列表
      total_page: 1, // 总页数
      current_page: 1, // 当前页面
      listRows: 10, // 每页数据量
      selectedIds: [], // 已选的商品id集合
      selectedItems: [], // 已选商品
      limit_num: 0, // 能兑换的商品数量
      isViewed: false, // 是否查看明细中
      showCheckCard: {
        card:false,
        phone:false,
        type:1
      }, // 是否显示卡密校验弹窗
      checkCardType: 1, // 当前打开校验卡密弹窗的主体，1：查询订单；2：知道了
      moveTop: this.$store.state.moveTop,
      is_dzx:false   //是否大闸蟹卡册商城
    };
  },
  async created() {
    let that = this;
    await this.getCardConfig();
    await this.getImgList();
    await this.getItemList();
    this.$nextTick(() => {
      const shallIPlay = function() {
        // i 表示每次滑动将要进入的页面的索引，可以通过 this.pages[i] 获取当前页面
        // 取得将要进入页面后便可以做进一步操作，比如，添加动画
        const i = -(this.currentPosition / this.viewHeight);
        var startY = 0;
        var endY;
        console.log({ i });
        let video_index = Number(that.config.video_index),
          video_url = that.config.video,
          audio_url = that.config.music;
        // video_index: 1.第一页放视频；2.最后一页放视频（商品之前的那页）
        if (video_index === 1) {
          if (i === 0 && video_url) {
            const audioEl = that.$refs.audio;
            if (!audioEl.paused) {
              console.log('1.暂停');
              audioEl.pause();
            }
            let _video = this.pages[i].getElementsByTagName("video")[0];
            if (_video) {
              this.pages[i].removeChild(_video);
            }

            let video = createVideo(video_url);

            this.pages[i].appendChild(video);
            this.pages[i].ontouchstart = function(e) {
              e.stopPropagation();
              startY = e.touches[0].pageY;
            };
            this.pages[i].ontouchend = function(e) {
              e.stopPropagation();
              endY = e.changedTouches[0].pageY;
              if (endY === startY) {
                video.play();
                video.removeAttribute('hidden')
              } else if (endY - startY < 0) {
                fullPageInst.goDown();
              } else {
                fullPageInst.goUp();
              }
            };
            return;
          }
          if (audio_url) {
            const audioEl = that.$refs.audio;
            const videoEl = document.querySelector('video');
            if (videoEl && !videoEl.paused) {
              videoEl.pause();
            }
            if (audioEl) {
              if (i > 0 && i <= that.img_list.length - 1) {
                if (audioEl.paused) {
                  // document.addEventListener('touchend', function() {
                  //   console.log('1. 添加播放事件');
                  //   audioEl.play();
                  // });
                  // 点击暂停播放音乐后，求换会继续播放
                  // audioEl.play();
                  // that.autoPlayAudio();
                } else {
                  return false;
                }
              } else {
                console.log('video_index == 1, 应该暂停播放');
                audioEl.pause();
              }
            }
          }
        } else {
          console.log('进到这里了？');
          if (i === that.img_list.length - 1 && video_url) {
            const audioEl = that.$refs.audio;
            if (!audioEl.paused) {
              audioEl.pause();
            }
            let _video = this.pages[i].getElementsByTagName("video")[0];
            if (_video) {
              this.pages[i].removeChild(_video);
            }

            let video = createVideo(video_url);

            this.pages[i].appendChild(video);
            this.pages[i].ontouchstart = function(e) {
              e.stopPropagation();
              startY = e.touches[0].pageY;
            };
            this.pages[i].ontouchend = function(e) {
              e.stopPropagation();
              endY = e.changedTouches[0].pageY;
              if (endY === startY) {
                video.play();
                video.removeAttribute('hidden');
              } else if (endY - startY < 0) {
                fullPageInst.goDown();
              } else {
                fullPageInst.goUp();
              }
            };
            return;
          }
          if (video_index === 2 && audio_url) {
            const audioEl = that.$refs.audio;
            const videoEl = document.querySelector('video');
            if (videoEl && !videoEl.paused) {
              videoEl.pause();
            }
            if (i > 0 && i <= that.img_list.length - 1) {
              if (audioEl.paused) {
                // document.addEventListener('touchend', function() {
                //   console.log('2. 添加播放事件');
                //   audioEl.play();
                // });
                audioEl.play();
                // that.autoPlayAudio();
              } else {
                return false;
              }
            } else {
              audioEl.pause();
            }
          }
          if (video_index === 3 && audio_url) {
            const audioEl = that.$refs.audio;
            if (i >= 0 && i <= that.img_list.length - 1) {
              if (audioEl.paused) {
                // document.addEventListener('touchend', function() {
                //   console.log('3. 添加播放事件');
                //     audioEl.play();
                //   });
                // audioEl.play();
                // that.autoPlayAudio();
              } else {
                return false;
              }
            } else {
              audioEl.pause();
            }
          }
        }
      };
      fullPageInst = new PureFullPage({
        delay: 1000,
        isShowNav: false,
        definePages: shallIPlay
      });
      fullPageInst.init();

      let video_index = Number(that.config.video_index),
        video_url = that.config.video,
        audio_url = that.config.music,
        pages = document.querySelectorAll(".page");
      // video_index: 1.第一页放视频；2.最后一页放视频（商品之前的那页）
      if (video_index === 1) {
        console.log('1.video_index == 1?');
        if (video_url) {
          let _video = pages[0].getElementsByTagName("video")[0];
          if (_video) {
            pages[0].removeChild(_video);
          }
          
          let startY = 0;
          let endY;
          
          let video = createVideo(video_url);

          pages[0].appendChild(video);
          pages[0].ontouchstart = function(e) {
            e.stopPropagation();
            startY = e.touches[0].pageY;
          };
          pages[0].ontouchend = function(e) {
            e.stopPropagation();
            endY = e.changedTouches[0].pageY;
            if (endY === startY) {
              video.play();
              video.removeAttribute('hidden');
            } else if (endY - startY < 0) {
              fullPageInst.goDown();
            } else {
              fullPageInst.goUp();
            }
          };
          // return;
        }
        if (audio_url) {
          this.$nextTick(() => {
            try {
              const audioEl = that.$refs.audio;
              if (audioEl && this.moveTop === 0) {
                this.autoPlayAudio();
                // document.addEventListener('touchend', function() {
                //   // console.log('捕获到点击事件');
                //   // audioEl.play();
                //   that.autoPlayAudio();
                // });
              } else {
                return false;
              }
            } catch (error) {
              throw error;
            }
          });
        }
      } else {
        console.log('video_index != 1, 进这里');
        if (audio_url) {
          this.$nextTick(() => {
            const audioEl = this.$refs.audio;
            if (audioEl) {
              if (audioEl.paused && this.moveTop === 0) {
                console.log(this.moveTop, "moveTop");
                // document.addEventListener('touchend', function() {
                //   console.log('4. 添加播放事件');
                //     audioEl.play();
                //   });
                // audioEl.play();
                this.autoPlayAudio();
              } else {
                return false;
              }
            }
          });
        }
      }
    });
  },
  mounted() {
   let scrollScreen = this.$store.state.moveTop
    if (scrollScreen !== 0) {
      let viewHeight = document.documentElement.clientHeight;
      const pureFullPage = document.getElementById('pureFullPage')
      pureFullPage.style.top = -(this.$store.state.moveTop * viewHeight) + 'px'
    }
    if (this.moveTop !== 0) {
      const audioEl = this.$refs.audio;
      audioEl.pause()
    }
    let bodyHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    // console.log({bodyHeight});
    this.bodyHeight = bodyHeight;
  },
  methods: {
    ...mapMutations(["setCardData"]),
    // 获取配置信息
    async getCardConfig() {
      try {
        let res = await getConfig();
        if (res.code === 0) {
          let config = res.data;
          this.config = config;
          console.log(this.config)
          this.listRows = config.show_number;
          this.limit_num = config.item_num;
          document.title = config.card_name;
          setStore("card_config", config);
        } else {
        }
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    },
    // 获取图片list
    async getImgList() {
      try {
        let res = await getImgs();
        if (res.code === 0) {
          let list = res.data;
          let video_url = this.config.video;
          let video_index = Number(this.config.video_index);
          let video_pic = this.config.video_pic;
          if (video_url && video_pic) {
            if (video_index === 1) {
              this.img_list = [{ img: video_pic }, ...list];
            } else if (video_index === 2) {
              this.img_list = [...list, { img: video_pic }];
            } else {
              this.img_list = list;
            }
          } else {
            this.img_list = list;
          }
        } else {
        }
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    },
    // 获取商品列表
    async getItemList() {
      try {
        let res = await getItemListCard(this.current_page, this.listRows);
        if (res.code === 0) {
          let { total, data } = res.data;
          this.total_page = Math.ceil(Number(total) / Number(this.listRows));
          this.item_list = data;
        } else {
					this.$dialog.toast({
					  mes: res.msg,
					  timeout: 1500,
					  icon: "error"
					});
        }
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    },
    // 点击上一页
    handlePrevPage() {
      if (this.current_page - 1 < 1) {
        return;
      }
      this.current_page -= 1;
      this.getItemList();
    },
    //客户确认是否请求客服
    getService() {
          this.$dialog.confirm({
              title:'请确认',
              mes: `<br/>&nbsp;&nbsp;即将前往客服咨询,您是否要联系客服?<br/>`,
              opts: () => {
                console.log('你确认了')
                  var isSdkReady = false;
                  ysf("onready", function() {
                    isSdkReady = true;
                  });
                  // 判断 点击客服跳转到客服中心
                  if (isSdkReady) {
                    location.href = ysf("url");
                  }
                  let self = this;
                  ysf("product", {
                    show: 1, // 1为打开， 其他参数为隐藏（包括非零元素）
                    title: "壹企通",
                    desc: "壹企通",
                    picture: "壹企通",
                    note: "壹企通",
                    url: window.location.href,
                    success: function() {
                      console.log('成功了')
                      // 成功回调
                      if (isSdkReady) {
                        ysf("open");
                      }
                    },
                    error: function() {
                      console.log('失败了')
                      // 错误回调
                      // handle error
                    }
                  });
              }
          });
    },
    // 点击下一页
    handleNextPage() {
      if (this.current_page + 1 > this.total_page) {
        return;
      }
      this.current_page += 1;
      this.getItemList();
    },
    // 挑选商品
    selectItem(item) {
      let id = item.id;
      let index = this.selectedIds.indexOf(id);
      if (index === -1) {
        if (this.selectedIds.length < Number(this.limit_num)) {
          this.selectedIds.push(id);
          this.selectedItems.push(item);
        } else {
          this.$dialog.toast({
            mes: `最多挑选${this.limit_num}件哦`
          });
          return;
        }
      } else {
        this.selectedIds.splice(index, 1);
        this.selectedItems.splice(index, 1);
      }
    },
    // 触摸商品开始
    handleTouchstart(e) {
      this.startY = e.touches[0].pageY;
      // console.log('start', e);
    },
    // 触摸商品结束
    handleTouchend(e, item) {
      this.endY = e.changedTouches[0].pageY;
      // console.log('end',e);
      // console.log({fullPageInst});
      if (this.endY === this.startY) {
        this.selectItem(item);
      } else if (this.endY - this.startY < 0) {
        fullPageInst.goDown();
      } else {
        fullPageInst.goUp();
      }
    },
    // 打开校验卡密弹窗
    async openCheckCard(type) {
      this.checkCardType = Number(type);
      if (Number(type) === 2) {
        this.showCheckCard.type=1;
        if (!this.selectedIds.length) {
          this.$dialog.toast({
            mes: `请先挑选商品哦`
          });
          return;
        }
        if (this.selectedIds.length !== Number(this.limit_num)) {
          this.$dialog.toast({
            mes: `请先选完${this.limit_num}件商品`
          });
          return;
        }
        try {
          this.showCheckCard.phone=false;
          const res = await checkItemCredit(this.selectedIds.join(","), localStorage.getItem("bid"));
          if (res.code === 0) {
            this.showCheckCard.card = true;
             this.showCheckCard.phone=true;
          } else {
            this.$dialog.toast({
              mes: res.msg
            });
          }
        } catch (error) {
          console.warn("Something bad happened: ", error);
        }
        return;
      }
      this.showCheckCard.card = true;
      this.showCheckCard.phone=true;
      this.showCheckCard.type=2;
    },
    // 关闭校验卡密弹窗
    closeCheckCard() {
      this.showCheckCard.card = false;
    },
    // 提交校验卡密表单
    async checkCard(card_sn, card_pass, type) {

      this.setCardData({ card_sn, card_pass, type });
      if (this.checkCardType === 1) {
        if(type==2){

        let res = await getOrderList({
            mobile: card_sn,
            code: card_pass,
            type: type
          });
          if(res.code == 0){
             this.$router.push({
              path: "/check_order"
          });
          }else {
            this.$dialog.toast({
              mes: res.msg
            })
          }
        }else{
             let res = await getOrderList({
            card_sn: card_sn,
            card_pass: card_pass
          });
          if(res.code == 0){
             this.$router.push({
              path: "/check_order"
          });
          }else {
            this.$dialog.toast({
              mes: res.msg
            })
          }
        }
      } else if (this.checkCardType === 2) {
        try {
          let res;
          if(type==2){
             let check=await verifyMobile(card_sn,card_pass)
             if(check.code==0){
                 res = await checkTicketByMobile({card_sn: card_sn,card_pass: '0'});
                 type=3;
                 this.setCardData({ card_sn, card_pass, type });
             }else{
                 this.$dialog.toast({
                    mes: check.msg
                  })
             }
          }else{
               res = await checkTicket({card_sn:card_sn,card_pass: card_pass});
          }
          if (res.code === 0) {
            // let cur_url = window.location.href;
            // let target_url = cur_url.split('#')[0] + '#/card_exchange?item_ids=' + this.selectedIds.join(",");
            // window.location.href = target_url;
            this.$router.push({
              path: "/card_exchange",
              query: {
                item_ids: this.selectedIds.join(",")
              }
            });
          } else {
            this.$dialog.toast({
              mes: res.msg
            })
          }
        } catch (error) {
          console.warn("Something bad happened: ", error);
        }
      }
    },
    // 移除选中
    removeSelected(id) {
      let index = this.selectedIds.indexOf(id);
      this.selectedIds.splice(index, 1);
      this.selectedItems.splice(index, 1);
      if (!this.selectedItems.length) {
        this.isViewed = false;
      }
    },
    // 点击查看明细
    checkDetails() {
      if (!this.selectedItems.length) {
        this.$dialog.toast({
          mes: `请先挑选商品哦`
        });
        return;
      }
      this.isViewed = !this.isViewed;
    },
    handlePlay() {
      const audioEl = this.$refs.audio;
      if (audioEl) {
        if (audioEl.paused) {
          audioEl.play();
        } else {
          audioEl.pause();
        }
      }
    },
    handleVideoPlay() {
      this.$nextTick(() => {
        let icon = document.querySelector('#icon');
        let video_url = this.config.video
        icon.setAttribute('style', 'display: none');
        let video = document.getElementById('video');
        video.play();
      })
    },
    autoPlayAudio() {
      
      const audioEl = this.$refs.audio;
      console.log('ready play?', audioEl);
      wx.config({
        // 配置信息, 即使不正确也能使用 wx.ready
        debug: false,
        appId: appId,
        timestamp: timestamp,
        nonceStr: nonceStr,
        signature: signature,
        jsApiList: []
      });
      wx.ready(function() {
        console.log('播放了嘛');
        audioEl.play();
      });
    },
    // 点击查看商品详情
    toDetail (item) {
      let moveTop = this.img_list.length
      this.$store.commit('changeMoveTop', moveTop)
      this.$router.push({
        name: "card_goods_details",
        query: {
          item_id: item.item_id
        }
      });
    },
    //查看规则
    checkRule () {
      let moveTop = this.img_list.length
      this.$store.commit('changeMoveTop', moveTop)
      this.$router.push("/exchange_rules",);
    }
  },
  computed: {
    ...mapGetters({
      moveTop: state => state.moveTop
    }),
    ...mapState({
      businessInfo: state => state.businessInfo
    })
  },
  watch: {
    "businessInfo.id": {
      handler(id) {
        if(id == '484'||id == '410'||id == '2477'||id == '1582'||id == '412'||id == '679'){
            this.is_dzx = true
          }else{
             this.is_dzx = false
          }
      },
      immediate: true
    }
  },
  beforeDestroy () {
    fullPageInst.destroy();
  }
  // beforeRouteEnter (to, from, next) {
  // },
  // beforeRouteLeave (to, from, next) {
  //   this.$store.commit('changeMoveTop', moveTop)
  //   next()
  // }
};
</script>

<style>
#pureFullPage {
  transition: all 0.3s ease-in-out;
}

#pureFullPage .page video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #000;
}

[v-cloak] {
  display: none !important;
}
</style>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";
.page {
  position: relative;
  background-color: #f7f7f7;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  header {
    position: relative;
    .fj;
    align-items: center;
    .wh(100%, 0.88rem);
    padding-left: 0.24rem;
    padding-right: 0.16rem;
    line-height: 0.88rem;
    background-color: #fff;
    box-sizing: border-box;
    .rule {
      .sc(@fontsize-medium, #ff4c4c);
    }
    .kf-box{
      .sc(@fontsize-medium, #ff4c4c);
      img{
        margin-top: -2px;
        width: 20px;
        vertical-align: middle;
      }

    }
    .check-order-btn {
      .txt {
        .sc(@fontsize-medium, #ff4c4c);
      }
      .icon-rightarrow {
        display: inline-block;
        .wh(0.24rem, 0.24rem);
      }
    }
  }
        .no-stock{
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        
      }
      .sq-img{
          position: absolute;
          width: .8rem;
          right: 0;
          z-index: 99999;
        }
        .sq-img1{
          position: absolute;
          width: .8rem;
          left: 0;
          z-index: 9;
        }
  main {
    .six-item-list {
      .clear-fix;
      padding-left: 0.2rem;
      padding-right: 0.18rem;
      padding-bottom: 0.24rem;
      .item {
        position: relative;
        .float(left);
        .wh(2.32rem, 3.44rem);
        border: 0.04rem solid #fff;
        margin-top: 0.24rem;
        margin-right: 0.08rem;
        background-color: #fff;
        box-sizing: border-box;
        .img-box {
          .wh(100%, 2.32rem);
          overflow: hidden;
          img {
            display: block;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
          }
        }
        .img-bottom {
          padding: 0.16rem 0.08rem;
          .wh(100%, 0.8rem);
          .item-name {
            .ellipsis(2);
            .sc(@fontsize-medium, @color-dark-grey);
            line-height: 0.4rem;
            word-wrap: break-word;
            word-break: break-all;
          }
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
        &.active {
          border: 0.04rem solid #ff4c4c;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            .wh(0.48rem, 0.48rem);
            background-image: url("../../assets/icon_chooseon.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
    .four-item-list {
      .clear-fix;
      padding-left: 0.48rem;
      padding-right: 0.46rem;
      padding-bottom: 0.24rem;
      .item {
        position: relative;
        .float(left);
        .wh(3.2rem, 3.2rem);
        border: 0.04rem solid #fff;
        margin-top: 0.2rem;
        margin-right: 0.14rem;
        background-color: #fff;
        box-sizing: border-box;
        .img-box {
          .wh(2.88rem, 2.2rem);
          margin: 0 auto;
          overflow: hidden;
          img {
            display: block;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
          }
        }
        .img-bottom {
          padding: 0.16rem 0.16rem 0.32rem;
          .item-name {
            .ellipsis(2);
            .sc(@fontsize-medium, @color-dark-grey);
            line-height: 0.4rem;
            word-wrap: break-word;
            word-break: break-all;
          }
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
        &.active {
          border: 0.04rem solid #ff4c4c;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            .wh(0.48rem, 0.48rem);
            background-image: url("../../assets/icon_chooseon.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      .btn {
        .wh(1.76rem, 0.64rem);
        .sc(@fontsize-medium, #ff4c4c);
        line-height: 0.64rem;
        text-align: center;
        white-space: nowrap;
        background-color: #fff;
        border-radius: 0.08rem;
      }
      .current-page {
        .wh(2.48rem, 0.64rem);
        .sc(@fontsize-medium, @color-dark-grey);
        line-height: 0.64rem;
        text-align: center;
        white-space: nowrap;
      }
    }
  }
  footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    .wh(100%, 1.12rem);
    padding-left: 0.26rem;
    background-color: #fff;
    z-index: 5;
    box-sizing: border-box;
    .selected {
      display: inline-block;
      margin-right: 0.16rem;
      .sc(@fontsize-large, @color-dark-grey);
      line-height: 0.44rem;
      font-weight: bold;
      .num {
        display: inline-block;
        margin-left: 0.08rem;
        margin-right: 0.08rem;
        .sc(@fontsize-large, #ff4c4c);
        line-height: 0.44rem;
        font-weight: bold;
      }
    }
    .view-details-btn {
      .txt {
        margin-right: 0.06rem;
        .sc(@fontsize-medium, @color-grey);
        line-height: 0.4rem;
      }
      .icon-arrow {
        display: inline-block;
        .wh(0.14rem, 0.14rem);
        vertical-align: middle;
        transition: all 0.3s ease-in-out;
      }
      &.active {
        .icon-arrow {
          transform: rotate(180deg);
        }
      }
    }
    .submit-btn {
      .ct;
      right: 0;
      .wh(2.4rem, 1.12rem);
      .sc(@fontsize-large-x, @color-white);
      line-height: 1.12rem;
      text-align: center;
      white-space: nowrap;
      background-color: #ff4c4c;
      z-index: 6;
    }
  }
}
.up-tip {
  .cl;
  bottom: 0.24rem;
  .wh(1.6rem, 0.56rem);
  line-height: 0.56rem;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border-radius: 0.28rem;
  z-index: 5;
  .icon-uparrow {
    display: inline-block;
    .wh(0.32rem, 0.32rem);
    margin-right: 0.08rem;
    vertical-align: middle;
  }
  .tip {
    .sc(@fontsize-small, @color-dark-grey);
  }
}

.music-wrap {
  position: absolute;
  top: 0.4rem;
  right: 0.4rem;
  .wh(0.78rem, 0.78rem);
  img {
    .wh(100%, 100%);
  }
}
.icon-size {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 50%;
  margin-top: -0.75rem;
  left: 50%;
  margin-left: -0.75rem
}
</style>
