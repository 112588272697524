var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "page",
      on: {
        touchstart: function ($event) {
          $event.stopPropagation()
        },
        touchmove: function ($event) {
          $event.stopPropagation()
        },
        touchend: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _vm.order_list.length
        ? _vm._l(_vm.order_list, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "order-list" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "order-item",
                    attrs: {
                      to: {
                        path: "/check_order_details",
                        query: { order_id: item.id },
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "order-title" }, [
                      _c("h3", { staticClass: "order-number" }, [
                        _vm._v("单号：" + _vm._s(item.order_sn)),
                      ]),
                    ]),
                    _c("div", { staticClass: "order-cont" }, [
                      _c("div", { staticClass: "goods-img-box" }, [
                        item && item.order_item && item.order_item.base_pic
                          ? _c("img", {
                              attrs: { src: item.order_item.base_pic },
                            })
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "img-right" }, [
                        item && item.order_item && item.order_item.item_name
                          ? _c("p", { staticClass: "goods-name" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.order_item.item_name) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        item && item.order_item && item.order_item.property
                          ? _c("p", { staticClass: "goods-name" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.order_item.property) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "order-status" }, [
                      _vm._v(
                        _vm._s(
                          item.refund_status == 0 ||
                            item.refund_status == 2 ||
                            item.refund_status == 4
                            ? "售后中"
                            : _vm.getStateStr(item.order_status)
                        )
                      ),
                    ]),
                  ]
                ),
                _vm.is_dzx && item.user_remark
                  ? _c("p", { staticClass: "user-remark" }, [
                      _vm._v("发货时间" + _vm._s(item.user_remark)),
                    ])
                  : _vm._e(),
                item.customer_config &&
                item.customer_config.telephone &&
                item.customer_config.telephone_open
                  ? _c(
                      "div",
                      {
                        staticClass: "service",
                        on: {
                          click: function ($event) {
                            return _vm.getTelephone(
                              item.customer_config.telephone
                            )
                          },
                        },
                      },
                      [
                        _c("yd-icon", {
                          attrs: {
                            name: "phone2",
                            size: ".3rem",
                            color: "#FF685D",
                          },
                        }),
                        _c("span", { staticStyle: { color: "#FF685D" } }, [
                          _vm._v("销售热线"),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "yd-popup",
                  {
                    attrs: { position: "center", width: "80%" },
                    model: {
                      value: _vm.show1,
                      callback: function ($$v) {
                        _vm.show1 = $$v
                      },
                      expression: "show1",
                    },
                  },
                  [
                    _vm.telephone
                      ? _c("div", { staticClass: "kf-box" }, [
                          _c("img", {
                            attrs: { src: require("@/assets/ic_back_.png") },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.closeCall.apply(null, arguments)
                              },
                            },
                          }),
                          _c("p", { staticClass: "kf-mobile" }, [
                            _vm._v(
                              "\n                  销售热线:\n                  "
                            ),
                            _c("span", [_vm._v(_vm._s(_vm.telephone))]),
                          ]),
                          _c(
                            "p",
                            { staticClass: "btn-box" },
                            [
                              _c("span", { staticClass: "dial" }, [
                                _c(
                                  "a",
                                  { attrs: { href: "tel:" + _vm.telephone } },
                                  [_vm._v("确认呼叫")]
                                ),
                              ]),
                              _c(
                                "yd-button",
                                {
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.show1 = false
                                    },
                                  },
                                },
                                [_vm._v("取消")]
                              ),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ],
              1
            )
          })
        : _c(
            "div",
            { staticClass: "empty-wrap" },
            [_c("empty", { attrs: { icon: _vm.emptyIcon } })],
            1
          ),
      _c("div", { staticClass: "bottom" }, [
        _vm.kf_mobile
          ? _c(
              "a",
              { staticClass: "kf", attrs: { href: "tel:" + _vm.kf_mobile } },
              [_vm._v("联系客服 " + _vm._s(_vm.kf_mobile))]
            )
          : _vm._e(),
        _vm.desc
          ? _c(
              "div",
              {
                staticClass: "desc",
                domProps: { innerHTML: _vm._s(_vm.desc) },
              },
              [_vm._v(_vm._s(_vm.desc))]
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }