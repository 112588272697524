var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-address" },
    [
      _c("div", { staticClass: "cell-group" }, [
        _c("div", { staticClass: "cell" }, [
          _c("span", { staticClass: "form-label" }, [_vm._v("收货人")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.real_name,
                expression: "real_name",
              },
            ],
            attrs: {
              type: "text",
              placeholder: "请填写收货人姓名",
              maxlength: "10",
            },
            domProps: { value: _vm.real_name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.real_name = $event.target.value
              },
            },
          }),
        ]),
        _c("div", { staticClass: "cell" }, [
          _c("span", { staticClass: "form-label" }, [_vm._v("联系电话")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.mobile,
                expression: "mobile",
              },
            ],
            attrs: {
              type: "tel",
              placeholder: "请填写联系电话号码",
              maxlength: "11",
            },
            domProps: { value: _vm.mobile },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.mobile = $event.target.value
              },
            },
          }),
        ]),
        _c(
          "div",
          {
            staticClass: "area-box df fdr alc",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.showArea.apply(null, arguments)
              },
            },
          },
          [
            _c("span", { attrs: { slot: "left" }, slot: "left" }, [
              _vm._v("省市区"),
            ]),
            _vm.area_string
              ? _c("div", { staticClass: "area-string df alc" }, [
                  _vm._v(_vm._s(_vm.area_string)),
                ])
              : _c("div", { staticClass: "no-area" }, [_vm._v("请选择")]),
          ]
        ),
        _c("div", { staticClass: "cell" }, [
          _c("span", { staticClass: "form-label" }, [_vm._v("详细地址")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.address,
                expression: "address",
              },
            ],
            attrs: {
              type: "text",
              placeholder: "请填写详细地址",
              maxlength: "20",
            },
            domProps: { value: _vm.address },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.address = $event.target.value
              },
            },
          }),
        ]),
        _vm.is_show
          ? _c(
              "div",
              { staticClass: "cell" },
              [
                _c(
                  "yd-cell-group",
                  [
                    _c(
                      "yd-cell-item",
                      { attrs: { arrow: "" } },
                      [
                        _c("span", { attrs: { slot: "left" }, slot: "left" }, [
                          _vm._v("发货日期"),
                        ]),
                        _c("yd-datetime", {
                          staticClass: "datetime",
                          attrs: {
                            slot: "right",
                            type: "date",
                            "start-date": _vm.get_time,
                          },
                          slot: "right",
                          model: {
                            value: _vm.date_time,
                            callback: function ($$v) {
                              _vm.date_time = $$v
                            },
                            expression: "date_time",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("selectAddress", {
        ref: "address",
        attrs: { edit_date: _vm.edit_date },
        on: { confirmAddress: _vm.confirmAddress },
      }),
      _vm.time_list.length
        ? _c("div", { staticClass: "dzx-time" }, [
            _vm._m(0),
            _c(
              "ul",
              _vm._l(_vm.time_list, function (item, index) {
                return _c("li", { key: index }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm._f("formatDateStdC")(item[0] / 1000)) +
                      "~ " +
                      _vm._s(_vm._f("formatDateStdC")(item[1] / 1000)) +
                      "\n      "
                  ),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm.is_submit
        ? _c("footer", { on: { click: _vm.submit } }, [
            _c("div", { staticClass: "btn save-btn" }, [_vm._v("确定")]),
          ])
        : _c("footer", [
            _c("div", { staticClass: "btn save-btn" }, [_vm._v("确定")]),
          ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("\n      温馨提示："),
      _c("br"),
      _vm._v(
        "\n      因特殊原因,以下时间段暂时不可预约发货时间，请您另行选择，不便之处，敬请谅解!\n    "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }