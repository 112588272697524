// 定义mixins规范（公用方法复用）

//// 弹窗提示
export const toolTip = {
  data() {
    return {
      type: "", // 弹窗类型
      show_tip: false, // 控制弹窗显示
      tip: "" // 提示字符
    };
  },
  methods: {
    // 显示弹窗
    show_layer(type, tip) {
      this.type = type;
      this.tip = tip;
      this.show_tip = true;
    },
    // 改变弹窗显示
    changeShow(val) {
      this.show_tip = val;
    }
  }
};

//// input 输入框键盘弹起防遮挡
export const inputMixins = {
  mounted() {
    // input 键盘焦点问题修复
    window.addEventListener("resize", () => {
      const activeElement = document.activeElement;
      if (
        activeElement.tagName === "INPUT" ||
        activeElement.tagName === "TEXTAREA"
      ) {
        setTimeout(() => {
          activeElement.scrollIntoView();
        }, 100);
      }
    });
  },
  methods: {
    // inputFocus() {
    //   this.timer = setTimeout(() => {
    //     this.scrollToEnd();
    //   }, 500);
    // },
    // scrollToEnd() {
    //   this.$refs.wrapper.scrollTop = this.$refs.wrapper.scrollHeight;
    // },
    // clearScroll() {
    //   clearInterval(this.timer);
    // }
  }
};
