var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "empty flex-center" }, [
    _c("div", { staticClass: "img-box" }, [
      _c("div", { staticClass: "empty-img" }, [
        _c("img", { attrs: { src: _vm.icon, alt: "" } }),
      ]),
    ]),
    _c("p", { staticClass: "empty-tip" }, [_vm._v(_vm._s(_vm.tip))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }