/**
 * @todo: 空闲控制 返回函数连续调用时，空闲时间必须大于或等于 delay，func 才会执行
 * @param: func {function} 延迟时间，单位毫秒
 * @param: delay {number} 请求关联函数，实际应用需要调用的函数
 * @return: {function}    返回客户调用函数
 * @author: zcl
 * @date: 2019-03-28 11:03:16
 * @version: V1.0.0
 */
export function debounce(func, delay) {
  var timer;
  return function () {
    var _this = this;
    var args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      func.apply(_this, args);
    }, delay);
  };
}
