<template>
  <div class="alert-container" v-show="show" @touchend.self.stop="clickMask">
    <div class="alert-wrapper">
      <main @touchstart.stop @touchmove.stop @touchend.stop>
        <div class="item-list">
          <div class="item" v-for="item in list" :key="item.id" @touchend.stop>
            <div class="img-box">
              <img :src="item.base_pic" alt />
            </div>
            <div class="img-right">
              <p class="item-name">{{item.item_name}}</p>
            </div>
            <div class="close-btn-wrap flex-center" @touchend.stop="removeSelect(item.id)">
              <span class="close-btn"></span>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectedDetails",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    // 点击删除
    removeSelect(id) {
      this.$emit("handleRemove", id);
    },
    // 点击遮罩
    clickMask() {
      this.$emit('clickMask');
    }
  },
  watch: {
    value: {
      handler: function(val) {
        this.show = val;
      },
      immediate: true
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.alert-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.5);
  .alert-wrapper {
    .cl;
    bottom: 0;
    padding-bottom: 0.88rem;
    padding-left: 0.32rem;
    width: 100%;
    max-height: 6.94rem;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    main {
      .item-list {
        .item {
          position: relative;
          display: flex;
          align-items: center;
          .wh(100%, 1.6rem);
          border-bottom: 1px solid #d8d8d8;
          box-sizing: border-box;
          .img-box {
            .wh(0.96rem, 0.96rem);
            margin-right: 0.08rem;
            img {
              max-width: 100%;
              max-height: 100%;
              margin: auto;
            }
          }
          .img-right {
            max-width: 5.3rem;
            .item-name {
              .ellipsis(2);
              .sc(@fontsize-medium, @color-dark-grey);
              line-height: 0.4rem;
              font-weight: bold;
              word-wrap: break-word;
              word-break: break-all;
            }
          }
          .close-btn-wrap {
            .ct;
            right: 0;
            .wh(.8rem, .8rem);
          }
          .close-btn {
            width: 0.2em;
            height: 1em;
            background: #666;
            transform: rotate(45deg);
            display: inline-block;
            cursor: pointer;
            &:after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 0.2em;
              height: 1em;
              background: #666;
              transform: rotate(270deg);
            }
          }
          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
</style>