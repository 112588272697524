var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "pureFullPage" } },
    [
      _vm._l(_vm.img_list, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "page",
            style: { backgroundImage: "url(" + item.img + ")" },
          },
          [
            _vm._m(0, true),
            _vm.config.music
              ? _c(
                  "div",
                  {
                    staticClass: "music-wrap",
                    on: {
                      touchend: function ($event) {
                        $event.stopPropagation()
                        return _vm.handlePlay.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/icon_music.png"),
                        alt: "",
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm.config.video &&
            ((_vm.config.video_index == 1 && index == 0) ||
              (_vm.config.video_index == 2 && index == _vm.img_list.length - 1))
              ? _c(
                  "div",
                  {
                    staticClass: "icon-size",
                    attrs: { id: "icon" },
                    on: {
                      touchend: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleVideoPlay.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/icon_play.png"),
                        alt: "",
                      },
                    }),
                  ]
                )
              : _vm._e(),
          ]
        )
      }),
      _vm.item_list.length
        ? _c(
            "div",
            { ref: "scrollDom", staticClass: "page" },
            [
              _c("header", [
                _c(
                  "div",
                  {
                    staticClass: "rule",
                    on: {
                      click: function ($event) {
                        return _vm.checkRule()
                      },
                    },
                  },
                  [_vm._v("查看《商城兑换规则》")]
                ),
                _vm.is_dzx
                  ? _c(
                      "div",
                      {
                        staticClass: "kf-box",
                        on: {
                          click: function ($event) {
                            return _vm.getService()
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("../../assets/kf.png"),
                            alt: "",
                          },
                        }),
                        _c("span", [_vm._v("客服")]),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "btn check-order-btn",
                    on: {
                      touchend: function ($event) {
                        $event.stopPropagation()
                        return _vm.openCheckCard(1)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "txt" }, [_vm._v("查询订单")]),
                    _c("img", {
                      staticClass: "icon icon-rightarrow",
                      attrs: {
                        src: require("@/assets/icon_redarrow.png"),
                        alt: "",
                      },
                    }),
                  ]
                ),
              ]),
              _c("main", [
                Number(_vm.listRows) === 6
                  ? _c(
                      "div",
                      { staticClass: "six-item-list" },
                      _vm._l(_vm.item_list, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "item",
                            class: {
                              active: _vm.selectedIds.indexOf(item.id) !== -1,
                            },
                          },
                          [
                            !item.stock
                              ? _c("img", {
                                  staticClass: "sq-img1",
                                  attrs: { src: require("@/assets/2sq.png") },
                                })
                              : _vm._e(),
                            !item.stock
                              ? _c("div", { staticClass: "no-stock" }, [
                                  _c("div", { staticClass: "img-box" }, [
                                    _c("img", {
                                      attrs: { src: item.base_pic, alt: "" },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "img-bottom" }, [
                                    _c("p", { staticClass: "item-name" }, [
                                      _vm._v(_vm._s(item.item_name)),
                                    ]),
                                  ]),
                                ])
                              : _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "img-box",
                                      on: {
                                        touchstart: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleTouchstart.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                        touchend: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleTouchend(
                                            $event,
                                            item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: { src: item.base_pic, alt: "" },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "img-bottom",
                                      on: {
                                        touchstart: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleTouchstart.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                        touchend: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.toDetail(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("p", { staticClass: "item-name" }, [
                                        _vm._v(_vm._s(item.item_name)),
                                      ]),
                                    ]
                                  ),
                                ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                Number(_vm.listRows) === 4
                  ? _c(
                      "div",
                      { staticClass: "four-item-list" },
                      _vm._l(_vm.item_list, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "item",
                            class: {
                              active: _vm.selectedIds.indexOf(item.id) !== -1,
                            },
                          },
                          [
                            !item.stock
                              ? _c("img", {
                                  staticClass: "sq-img1",
                                  attrs: { src: require("@/assets/2sq.png") },
                                })
                              : _vm._e(),
                            !item.stock
                              ? _c("div", { staticClass: "no-stock" }, [
                                  _c("div", { staticClass: "img-box" }, [
                                    _c("img", {
                                      attrs: { src: item.base_pic, alt: "" },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "img-bottom" }, [
                                    _c("p", { staticClass: "item-name" }, [
                                      _vm._v(_vm._s(item.item_name)),
                                    ]),
                                  ]),
                                ])
                              : _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "img-box",
                                      on: {
                                        touchstart: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleTouchstart.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                        touchend: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleTouchend(
                                            $event,
                                            item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: { src: item.base_pic, alt: "" },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "img-bottom",
                                      on: {
                                        touchstart: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleTouchstart.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                        touchend: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.toDetail(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("p", { staticClass: "item-name" }, [
                                        _vm._v(_vm._s(item.item_name)),
                                      ]),
                                    ]
                                  ),
                                ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _c("div", { staticClass: "pagination" }, [
                  _c(
                    "div",
                    {
                      staticClass: "btn prev-btn",
                      on: {
                        touchend: function ($event) {
                          $event.stopPropagation()
                          return _vm.handlePrevPage.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("上一页")]
                  ),
                  _c("div", { staticClass: "current-page" }, [
                    _vm._v(
                      _vm._s(_vm.current_page) + "/" + _vm._s(_vm.total_page)
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "btn next-btn",
                      on: {
                        touchend: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleNextPage.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("下一页")]
                  ),
                ]),
              ]),
              _c("footer", [
                _c("span", { staticClass: "selected" }, [
                  _vm._v("\n        已选\n        "),
                  _c("span", { staticClass: "num" }, [
                    _vm._v(
                      _vm._s(_vm.selectedIds.length) +
                        "/" +
                        _vm._s(_vm.limit_num)
                    ),
                  ]),
                  _vm._v("件\n      "),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "btn view-details-btn",
                    class: { active: _vm.isViewed },
                    on: {
                      touchend: function ($event) {
                        $event.stopPropagation()
                        return _vm.checkDetails.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "txt" }, [_vm._v("查看明细")]),
                    _c("img", {
                      staticClass: "icon icon-arrow",
                      attrs: {
                        src: require("@/assets/icon_uparrow.png"),
                        alt: "",
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "btn submit-btn flex-center",
                    on: {
                      touchend: function ($event) {
                        $event.stopPropagation()
                        return _vm.openCheckCard(2)
                      },
                    },
                  },
                  [_vm._v("选好了")]
                ),
              ]),
              _vm.showCheckCard.card
                ? _c("check-card", {
                    ref: "cardAlert",
                    attrs: { value: _vm.showCheckCard },
                    on: {
                      handleSubmit: _vm.checkCard,
                      handleClose: _vm.closeCheckCard,
                    },
                  })
                : _vm._e(),
              _c("selected-details", {
                attrs: { list: _vm.selectedItems },
                on: {
                  handleRemove: _vm.removeSelected,
                  clickMask: function ($event) {
                    _vm.isViewed = false
                  },
                },
                model: {
                  value: _vm.isViewed,
                  callback: function ($$v) {
                    _vm.isViewed = $$v
                  },
                  expression: "isViewed",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "audio",
        {
          ref: "audio",
          attrs: { src: _vm.config.music, preload: "", hidden: "" },
        },
        [_vm._v("亲 您的浏览器不支持html5的audio标签")]
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "up-tip" }, [
      _c("img", {
        staticClass: "icon icon-uparrow",
        attrs: { src: require("@/assets/img_uparrow.png"), alt: "" },
      }),
      _c("span", { staticClass: "tip" }, [_vm._v("上拉翻页")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }