<template>
  <div class="page" @touchstart.stop @touchmove.stop @touchend.stop>
    <!-- S 订单列表 -->
    <template v-if="order_list.length" >
    <div class="order-list" v-for="(item, index) in order_list" :key="index">
      <router-link
        :to="{ path: '/check_order_details', query: { order_id: item.id } }"
        class="order-item"
      >
        <div class="order-title">
          <h3 class="order-number">单号：{{ item.order_sn }}</h3>
        </div>
        <div class="order-cont">
          <div class="goods-img-box">
            <img
              :src="item.order_item.base_pic"
              v-if="item && item.order_item && item.order_item.base_pic"
            />
          </div>
          <div class="img-right">
            <p
              class="goods-name"
              v-if="item && item.order_item && item.order_item.item_name"
            >
              {{ item.order_item.item_name }}
            </p>
            <p
              class="goods-name"
              v-if="item && item.order_item && item.order_item.property"
            >
              {{ item.order_item.property }}
            </p>
        </div>
          </div>
        <div class="order-status">{{item.refund_status==0||item.refund_status==2||item.refund_status==4?"售后中":getStateStr(item.order_status) }}</div>
      </router-link>
        <p class="user-remark" v-if="is_dzx&&item.user_remark">发货时间{{item.user_remark}}</p>
        <div class="service" v-if="item.customer_config && item.customer_config.telephone && item.customer_config.telephone_open"  @click="getTelephone(item.customer_config.telephone)">
            <yd-icon name="phone2" size=".3rem" color="#FF685D"></yd-icon>
            <span style="color:#FF685D">销售热线</span>
        </div> 
        <yd-popup v-model="show1" position="center" width="80%" >
            <div class="kf-box" v-if="telephone">
                <img src="~@/assets/ic_back_.png" @click.stop="closeCall" />
                <p class="kf-mobile">
                    销售热线:
                    <span>{{telephone}}</span>
                </p>
                <p class="btn-box">
                    <span class="dial">
                      <a :href="'tel:' + telephone">确认呼叫</a>
                    </span>
                    <yd-button @click.native="show1 = false">取消</yd-button>
                </p>
            </div>
        </yd-popup>
    </div>
        </template>
    <!-- E 订单列表 -->

    <!-- S empty -->
    <div class="empty-wrap" v-else>
      <empty :icon="emptyIcon"></empty>
    </div>
    <!-- E empty -->

    <!-- S 底部联系方式（销售热线和富文本二选一） -->
    <div class="bottom">
      <a :href="'tel:' + kf_mobile" class="kf" v-if="kf_mobile"
        >联系客服 {{ kf_mobile }}</a
      >
      <div class="desc" v-if="desc" v-html="desc">{{ desc }}</div>
    </div>
    <!-- E 底部联系方式 -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getStore } from "@/utils/common";
import { getOrderList} from "@/services/cardMallApi";
import empty from "@/components/empty/Empty";
export default {
  name: "CheckOrder",
  components: {
    empty
  },
  data() {
    return {
      emptyIcon: require("@/assets/icon_kong_dingdan@2x.png"),
      order_list: [], // 订单列表
      kf_mobile: "", // 客服联系方式
      desc: "", // 描述
      dzx_service: false ,//大闸蟹商城客服客服配置
      show1:false,
      telephone:'',
      dzx_id:[484,410,2477,1582,412,679],
      is_dzx:false   //是否大闸蟹卡册商城
    };
  },
  computed: {
    ...mapState({
      businessInfo: state => state.businessInfo,
      card_sn: state =>
        state.card_data.card_sn ||
        (getStore("card_data") && JSON.parse(getStore("card_data")).card_sn),
      card_pass: state =>
        state.card_data.card_pass ||
        (getStore("card_data") && JSON.parse(getStore("card_data")).card_pass),
      type: state =>
        state.card_data.type ||
        (getStore("card_data") && JSON.parse(getStore("card_data")).type)
    })
  },
    watch: {
    "businessInfo.id": {
      handler(id) {
        for(let i in this.dzx_id){
          if(this.dzx_id[i] == id){
            this.is_dzx = true
          }
        }
      },
      immediate: true
    }
  },
  async created() {
    if (getStore("card_config")) {
      let config = JSON.parse(getStore("card_config"));
      this.kf_mobile = config.telephone;
      this.desc = config.desc;
    }
    await this.getData();
  },
  methods: {
    // 获取订单列表
    async getData() {
      try {
        let res;
        if (this.type == 2) {
          res = await getOrderList({
            mobile: this.card_sn,
            code: this.card_pass,
            type: this.type
          });
        } else if (this.type == 3) {
          res = await getOrderList({
            card_sn: this.card_sn,
            card_pass: this.card_pass,
            exchange_type:'1'
          });
        } else {
          res = await getOrderList({
            card_sn: this.card_sn,
            card_pass: this.card_pass
          });
        }
        if (res.code === 0) {
          this.order_list = res.data;
        }
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    },
    getTelephone(tel){
      console.log('111')
      this.telephone = tel
      this.show1 = true;
    },
     // 关闭电话弹出
    closeCall() {
      this.show1 = false;
    },
    // 获取状态字符串
    getStateStr(state) {
      switch (Number(state)) {
        case 1:
          return "待发货";
          break;
        case 2:
          return "待收货";
          break;
        case 3:
          return "已完成";
          break;
          case 0:
          return "售后中";
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.page {
  padding-top: 0.2rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  padding-bottom: 0.24rem;
  .service{
    display: flex;
    align-items: center;
    position: absolute;
    right: 0.6rem;
    top: 1.98rem;
    z-index: 99;
  }
  .user-remark{
    position: absolute;
    left: 2rem;
    top: 2rem;
    z-index: 99;
  }
  .bj(#fff);
  .order-list {
    position: relative;
    .order-item {
      display: block;
      position: relative;
      margin-bottom: 0.48rem;
      z-index: 1;
      .order-title {
        position: relative;
        padding-left: 24px;
        margin-bottom: 0.2rem;
        &::before {
          content: "";
          .ct;
          left: 0;
          .wh(0.04rem, 0.2rem);
          background-color: @color-main;
          border-radius: 1px;
        }
        .order-number {
          .sc(@fontsize-medium, @color-dark-grey);
          line-height: 0.4rem;
          font-weight: bold;
        }
      }
      .order-cont {
        position: relative;
        width: 100%;
        min-height: 1.92rem;
        padding-left: 2rem;
        padding-top: 0.36rem;
        padding-bottom: 0.36rem;
        padding-right: 0.24rem;
        background-color: #fff;
        box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        box-sizing: border-box;
        .goods-img-box {
          position: absolute;
          top: 0.16rem;
          left: 0.2rem;
          .wh(1.54rem, 1.54rem);
          img {
            .wh(100%, 100%);
          }
        }
        .img-right {
          .sc(@fontsize-medium, @color-dark-grey);
          line-height: 0.4rem;
          font-weight: bold;
          word-wrap: break-word;
          word-break: break-all;
          .ellipsis(2);
        }
      }
      .order-status {
        position: absolute;
        top: 0;
        right: 0;
        .sc(@fontsize-small, @color-dark-grey);
        line-height: 0.34rem;
        font-weight: bold;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .bottom {
    margin-top: 0.3rem;
    bottom: 0.24rem;
    left: 0;
    right: 0;
    .sc(@fontsize-medium, @color-grey);
    line-height: 0.4rem;
    text-align: center;
  }
  .kf-box{
    background-color:#fff;
    border-radius: .2rem;
    position: relative;
    img{
      width: 0.5rem;
      height: 0.5rem;
      float: right;
      margin: 0.2rem;
    }
    .kf-mobile{
        display: flex;
        height: 2.6rem;
        font-size: .38rem;
        justify-content: center;
        align-items: center;
    }
    .btn-box{
      width: 100%;
      display: flex;
      font-size: .3rem;
      height: .8rem;
      .dial{
        width: 50%;
         color: #fff;
        background: var(--main-color);
        text-align: center;
        line-height: .8rem;
         border-radius: 0 0 0 .2rem;
         a{
           display: block;
         }
      }
      button{
         width: 50%;
         font-size: .3rem;
         padding: 0;
         border-radius: 0 0 .2rem 0 ;
         height: .8rem;
         background: #f1f1f1;
         color: var(--main-color);
      }
    }
  }
}
</style>
