<template>
  <div class="page" @touchstart.stop @touchmove.stop @touchend.stop>
    <div
      class="home-icon"
      @click.stop="goBack()"
      v-if="!customAccount('VUE_APP_ZHONGHANG')"
    >
      <img src="~@/assets/ic_back_28.png" alt />
    </div>
    <div
      class="collect-icon"
      @click="backHome()"
      v-if="!customAccount('VUE_APP_ZHONGHANG')"
    >
      <img src="~@/assets/icon_shangpin_home@2x.png" alt />
    </div>
    <!-- <HeadNav/> -->
    <div class="content" v-html="content"></div>
  </div>
</template>

<script>
import { getCardRule } from "@/services/cardMallApi";
import { customAccount } from "../../utils/custom";
import { getStore } from "@/utils/common";
// import HeadNav from "@/components/common/HeadNav"
export default {
  name: "ExchangeRules",
  data() {
    return {
      content: "" // 兑换规则
    };
  },
  async created() {
    await this.getContent();
  },
  methods: {
    customAccount,
    // 获取卡册商城兑换规则
    async getContent() {
      try {
        let res;
        if (this.customAccount("VUE_APP_ZHONGHANG")) {
          this.content = JSON.parse(
            getStore("businessInfo")
          ).activity_rules_content;
        } else {
          res = await getCardRule();
          if (res.code === 0) {
            this.content = res.data;
          } else {
          }
        }
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    backHome() {
      this.$router.replace({
        path: "/home"
      });
    }
  }
  // components: {
  //   HeadNav
  // }
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.page {
  .bj(#fff);
  padding: 0.32rem;
  box-sizing: border-box;

  .content {
    .sc(@fontsize-large, @color-dark-grey);
    line-height: 0.44rem;
    margin-top: 0.7rem;
  }

  .home-icon,
  .collect-icon {
    width: 0.56rem;
    height: 0.56rem;
    border-radius: 0.56rem;
    position: absolute;
    z-index: 110;

    img {
      width: 0.56rem;
      height: 0.56rem;
    }
  }

  .home-icon {
    left: 0.3rem;
    top: 0.4rem;
  }

  .collect-icon {
    right: 0.3rem;
    top: 0.4rem;
  }
}
</style>
