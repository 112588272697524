<template>
  <div class="page" @touchstart.stop @touchmove.stop @touchend.stop>
    <div class="order-top">
      <div class="top-left">
        <img src="~@/assets/icon_dingdan_daifukuan@2x.png" />
        <span v-if="order_detail.order_status==1&&order_detail.refund_status!=0&&order_detail.refund_status!=2&order_detail.refund_status!=4">待发货</span>
        <span v-if="order_detail.order_status==2&&order_detail.refund_status!=0&&order_detail.refund_status!=2&order_detail.refund_status!=4">待收货</span>
        <span v-if="order_detail.order_status==3&&order_detail.refund_status!=0&&order_detail.refund_status!=2&order_detail.refund_status!=4">已完成</span>
        <span v-if="order_detail.refund_status==0||order_detail.refund_status==2||order_detail.refund_status==4">售后中</span>
      </div>
    </div>
    <div
      class="order-address"
      v-if="order_detail && order_detail.item_list && order_detail.item_list[0].source_type!=6 "
    >
      <p class="user-info">
        <span class="user-name fs-weight">{{order_detail.realname}}</span>
        <span class="user-phone fs-weight">{{order_detail.mobile | hideMobile}}</span>
      </p>
      <p class="user-address">{{order_detail.address}}</p>
      <div class="order-line"></div>
    </div>
    <div class="material-flow">
      <div class="goods-list">
        <div class="goods-item" v-for="item in order_detail.item_list" :key="item.item_id">
          <div class="img-box">
            <img :src="item.base_pic" alt />
          </div>
          <div class="img-right">
            <p class="item-name">{{item.item_name}}</p>
            <p class="item-name">{{item.property}}</p>
          </div>
            <p class="user-remark" v-if="is_dzx&&order_detail.user_remark">发货时间{{order_detail.user_remark}}</p>
            <div class="service" v-if="order_detail.customer_config&&order_detail.customer_config.telephone&&order_detail.customer_config.telephone_open" @click.stop="show_service = true">
              <yd-icon name="phone2" size=".3rem" color="#FF685D"></yd-icon>
              <span style="color:#FF685D">销售热线</span>
            </div> 
        </div>
      </div>
      <div id="tkwl" class="material-box" v-if="order_detail.refund_express_company||order_detail.refund_return_express_company">
        <p>{{order_detail.refund_express_company?`寄回物流：${order_detail.refund_express_company}  ${order_detail.refund_express_number}`:''}}</p>
        <p>{{order_detail.refund_return_express_company?`新发物流：${order_detail.refund_return_express_company}  ${order_detail.refund_return_express_number}`:''}}</p>
      </div>
      <div class="material-box" v-if="hasFlow">
        <div class="material-title" v-if="expName&&number">{{expName}}：{{number}}</div>
        <div class="material-line" v-for="(item,index) in logistics_list" :key="index">
          <div class="time">
            <p>{{item.date}}</p>
            <p>{{item.minutes}}</p>
          </div>
          <div class="info">
            <p>{{item.status}}</p>
          </div>
        </div>
      </div>
      <div class="flow-empty flex-center" v-else>
        <div class="img-box">
          <img src="~@/assets/img_location_blank@2x.png" alt="">
        </div>
        <div class="tip">暂无物流信息</div>
      </div>
    </div>
    <!-- 填写物流** -->
    <FillLogistics
      :id="order_detail.refund_id"
      :value="show_fill"
      @cancel="show_fill=false"
      @submitFill="submitFill"
      @click.stop="show_fill=true"
    ></FillLogistics>
     <!-- **填写物流 -->
    <!-- 取消售后申请** -->
    <confirm-alert
      :value="show_toast"
      :is_delete="true"
      :confirmText="confirmText"
      confirmBtnText="确认取消"
      @cancel="cancel"
      @confirm="confirm"
    ></confirm-alert>
    <!-- 销售热线弹窗 -->
    <yd-popup v-model="show_service" position="center" width="80%" >
      <div class="kf-box" v-if="order_detail.customer_config">
          <img src="~@/assets/ic_back_.png" @click.stop="show_service = false" />
          <p class="kf-mobile">
              销售热线:
              <span>{{order_detail.customer_config.telephone}}</span>
          </p>
          <p class="btn-box">
              <span class="dial">
                <a :href="'tel:' + order_detail.customer_config.telephone">确认呼叫</a>
              </span>
              <yd-button @click.native="show_service = false">取消</yd-button>
          </p>
      </div>
    </yd-popup>
    <!-- **取消售后申请 -->
    <div class="bottom">
      <div class="btn collect-btn flex-center" @click="openwuliu" v-if="order_detail.refund_status*1==2">填写物流</div>
      <div class="btn collect-btn flex-center" @click="cancelApply" v-if="order_detail.refund_status==0||order_detail.refund_status==2">取消申请</div>
      <!-- <div class="btn collect-btn flex-center" @click="applySale" v-if="order_detail.order_status!=1&&order_detail.refund_status*1!=0&&order_detail.refund_status*1!=2">申请售后</div> -->
       <div class="btn collect-btn flex-center" @click="applySale" v-if="order_detail.order_status==2&&order_detail.refund_status*1!=0&&order_detail.refund_status*1!=2&&order_detail.refund_status*1!=4&&order_detail.refund_status*1!=7">申请售后</div>
      <div class="btn collect-btn flex-center" @click="confirmReceipt" v-if="order_detail.order_status==2&&order_detail.refund_status*1!=0&&order_detail.refund_status*1!=2&&order_detail.refund_status*1!=4">确认收货</div>
      <div class="btn collect-btn flex-center" @click="openChat">联系客服</div>
    </div>
  </div>
</template>

<script>
import { orderDetail, getLogisticsInfo, confirmReceipt, editRefund,cancelOrder,cancelAfterSale,afterSaleDetail} from "@/services/orderApi.js";
import { ServicePhone } from "@/services";
import ConfirmAlert from "@/components/common/ConfirmAlert";
import { mapState } from "vuex";
import FillLogistics from "@/components/common/FillLogistics";
import { getStore } from "@/utils/common";
import "@/utils/qiyu";
export default {
  name: "CheckOrderDetails",
  data() {
    return {
      order_id: this.$route.query.order_id, // 订单 id
      order_detail: {}, // 订单详情信息
      item_list: [], // 订单商品列表
      logistics_list: [], // 物流信息
      expName: "", //公司
      number: "", //单号
      service_status: "", //是否开启客服
      service_mobile: "", //客服电话号
      hasFlow: false, // 是否有物流信息
      show_fill: false, //填写物流
      show_toast: false,
      show_service: false,
      dzx_id:[484,410,2477,1582,412,679],
      is_dzx:false   //是否大闸蟹卡册商城
    };
  },
   components: {
    FillLogistics,
    ConfirmAlert
  },
   computed: {
    ...mapState({
      businessInfo: state => state.businessInfo,
      type: state => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
      pay_password: state => !!state.userInfo.pay_password, //是否设置支付密码
      card_sn: state =>
        state.card_data.card_sn ||
        (getStore("card_data") && JSON.parse(getStore("card_data")).card_sn),
      card_pass: state =>
        state.card_data.card_pass ||
        (getStore("card_data") && JSON.parse(getStore("card_data")).card_pass),
      type: state =>
        state.card_data.type ||
        (getStore("card_data") && JSON.parse(getStore("card_data")).type)
    })
  },
  watch: {
    "businessInfo.id": {
      handler(id) {
        for(let i in this.dzx_id){
          if(this.dzx_id[i] == id){
            this.is_dzx = true
          }
        }
      },
      immediate: true
    }
  },
  async created() {
    await this.getOrderDetail();
    await this.getLogisticsInfo();
    await this.openchated();
    console.log(this.order_detail)
  },
  methods: {
    //取消售后申请
    cancelApply() {
      this.alertType = 2;
      this.confirmText = "您确定要取消申请么？";
      this.show_toast = true;
    },
    cancel() {
      this.show_toast = false;
      console.log(this.orderState,"orderState")
    },
    async confirm() {
      try {
        if (this.alertType == 1) {
          let data = await cancelOrder(this.order_detail.pay_order_id);
          if (data.code === 0) {
            this.$dialog.toast({
              mes: "取消成功",
              timeout: 1500,
              icon: "none",
              callback: () => {
                location.replace(location.href);
              }
            });
          } else {
            console.log("取消失败", data);
            this.cancel();
            this.$dialog.toast({
              mes: data.msg,
              timeout: 1500,
              icon: "error"
            });
          }
        } else if (this.alertType == 2) {
           this.show_toast = false;
          let data = await cancelAfterSale(this.order_detail.refund_id);
          if (data.code === 0) {
            this.$dialog.toast({
              mes: "取消成功",
              timeout: 1500,
              icon: "none",
              callback: () => {
                 this.getOrderDetail();
              }
            });
          } else {
            console.log("取消失败", data);
            this.cancel();
            this.$dialog.toast({
              mes: data.msg,
              timeout: 1500,
              icon: "error"
            });
          }
        }
      } catch (err) {
        throw err;
      }
    },
    //打开物流弹窗
    openwuliu(){
     this.show_fill=true
    },
    //填写物流信息
    async submitFill(item) {
      let params = item;
      try {
        const res = await editRefund(params);
        if (res.code === 0) {
          const data = res.data;
          console.log(data);
          this.show_fill = false;
          this.$dialog.toast({
            mes: "提交成功",
            timeout: 1500,
            icon: "none",
            callback: () => {
                // this.$router.go(0);
                window.location.reload()
            }
          });
        } else {
          console.log("提交失败", res);
          this.show_fill = false;
          this.$dialog.toast({
            mes: res.msg,
            timeout: 1500,
            icon: "error"
          });
        }
      } catch (e) {
        throw e;
      }
    },
      //   申请售后
    applySale() {
      this.$router.replace({
        path: "/sale-list",
        query: {
          // orderState: this.order_detail.order_status,
         orderState: 2,
          order_id: this.$route.query.order_id,
          // shopType: this.type
          shopType: 4
        }
      });
    },
     //客服按钮弹窗状态获取
    async openchated() {

      try {
        let res = await ServicePhone();
        let { data } = res;
        console.log(data.service_status);
        if (res.code == 0) {
          console.log(res);
          this.service_status = data.service_status; //开启
          this.service_mobile = data.service_mobile; //关闭
        }
      } catch (err) {
        throw err;
      }
    },
     //客服聊天
    openChat() {
     if(this.service_status==1){
       var isSdkReady = false;
      ysf("onready", function() {
        isSdkReady = true;
      });
      if (isSdkReady) {
        location.href = ysf("url");
      }
      let self = this;
      ysf("config", {
        unconfirm: 1,
        success: function() {
          // 成功回调
          ysf("open");
        },
        error: function() {
          // 错误回调
          // handle error
        }
      });
     }else{
       this.sermob_my=true;
     }
    },
    async getOrderDetail() {
      try {
        // let res = await orderDetail(this.order_id);
        let res;
        if (this.type == 2) {
          res = await orderDetail({
            mobile: this.card_sn,
            type: this.type,
            order_id: this.order_id
          });
        } else if (this.type == 3) {
          res = await orderDetail({
            card_sn: this.card_sn,
            card_pass: this.card_pass,
            exchange_type:'1',
            order_id: this.order_id
          });
        } else {
          res = await orderDetail({
            card_sn: this.card_sn,
            card_pass: this.card_pass,
            order_id: this.order_id
          });
        }
        if (res.code === 0) {
          this.order_detail = res.data;
        }
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }
    },
    async getLogisticsInfo() {
      try {
        let res;
        if (this.type == 2) {
          res = await getLogisticsInfo({
            mobile: this.card_sn,
            code: this.card_pass,
            type: this.type,
            order_id: this.order_id
          });
        } else if (this.type == 3) {
          res = await getLogisticsInfo({
            card_sn: this.card_sn,
            card_pass: this.card_pass,
            exchange_type:'1',
            order_id: this.order_id
          });
        } else {
          res = await getLogisticsInfo({
            card_sn: this.card_sn,
            card_pass: this.card_pass,
            order_id: this.order_id
          });
        }
        // const res = await getLogisticsInfo(this.order_id);
        if (res.code === 0) {
          const data = res.data;
          if (data.source_type) {
            this.hasFlow = true;
            if (data.orderLogistics.length > 0) {
              this.logistics_status = 0;
              this.logistics_list = data.orderLogistics.reverse();
              for (let i = 0; i < this.logistics_list.length; i++) {
                let time = this.logistics_list[i].time;
                let timeArr = time.split(" ");
                this.$set(
                  this.logistics_list[i],
                  "date",
                  timeArr[0].slice(5, 10)
                );
                this.$set(
                  this.logistics_list[i],
                  "minutes",
                  timeArr[1]
                );
              }
            }
          } else if (data.status == 0) {
            this.hasFlow = true;
            this.expName = data.result.expName;
            this.number = data.result.number;
            if (data.result.list.length > 0) {
              this.logistics_list = data.result.list;
              for (let i = 0; i < this.logistics_list.length; i++) {
                let time = this.logistics_list[i].time;
                let timeArr = time.split(" ");
                this.$set(
                  this.logistics_list[i],
                  "date",
                  timeArr[0].slice(5, 10)
                );
                this.$set(
                  this.logistics_list[i],
                  "minutes",
                  timeArr[1]
                );
              }
              console.log(this.logistics_list);
            }
          } else {
            this.hasFlow = false;
          }
        }
      } catch (e) {
        console.warn("Something bad happened: ", error);
      }
    },
    //  确认收货
    async confirmReceipt() {
      let id = this.order_id;
      try {
        if (id) {
          const res = await confirmReceipt(id);
          if (res.code === 0) {
            const data = res.data;
            console.log(data);
            this.$dialog.toast({
              mes: '确认收货成功',
            });
            this.$router.replace({
              name: 'check_order',
            })
          } else {
            this.$dialog.toast({
              mes: res.msg,
            });
          }
        }
      } catch (e) {

      }
    },
  }
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.page {
  .bj(#F7F7F7);
  padding-bottom: 1rem;
  box-sizing: border-box;
  .order-top {
    width: 100%;
    height: 1rem;
    background-color: #ff4c4c;
    padding: 0 0.3rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .top-left {
      img {
        width: 0.48rem;
        height: 0.48rem;
        margin-right: 6px;
      }

      display: flex;
      align-items: center;
      font-size: 0.3rem;
      color: #fff;
      font-weight: bold;
    }

    .top-right {
      color: #fff;
      font-size: 0.3rem;
    }
  }

  .order-address {
    height: 1.6rem;
    background: #fff;
    position: relative;
    padding: 0.36rem 0.34rem;
    box-sizing: border-box;
    line-height: 0.4rem;

    .user-info {
      font-size: 0.4rem;
      color: @color-dark-grey;
      font-weight: bold;
      margin-bottom: 3px;

      .user-name {
        margin-right: 0.26rem;
      }
    }

    .user-address {
      font-size: 0.22rem;
      color: #666666;
    }

    .order-line {
      height: 3px;
      width: 100%;
      background: url("~@/assets/img_dingdan_tiaoxiushi@2x.png") no-repeat;
      background-size: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
    .kf-box{
    background-color:#fff;
    border-radius: .2rem;
    position: relative;
    img{
      width: 0.5rem;
      height: 0.5rem;
      float: right;
      margin: 0.2rem;
    }
    .kf-mobile{
        display: flex;
        height: 2.6rem;
        font-size: .38rem;
        justify-content: center;
        align-items: center;
    }
    .btn-box{
      width: 100%;
      display: flex;
      font-size: .3rem;
      height: .8rem;
      .dial{
        width: 50%;
         color: #fff;
        background: var(--main-color);
        text-align: center;
        line-height: .8rem;
         border-radius: 0 0 0 .2rem;
         a{
           display: block;
         }
      }
      button{
         width: 50%;
         font-size: .3rem;
         padding: 0;
         border-radius: 0 0 .2rem 0 ;
         height: .8rem;
         background: #f1f1f1;
         color: var(--main-color);
      }
    }
  }

  .material-flow {
    padding: 0.3rem 0.3rem 0;
    box-sizing: border-box;

    .goods-list {
      width: 100%;

      .goods-item {
        display: flex;
        align-items: center;
        position: relative;
        .wh(100%, 1.92rem);
        margin-bottom: .24rem;
        padding-left: .08rem;
        padding-right: .24rem;
        background-color: #fff;
        box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.05);
        border-radius: 4px;
        box-sizing: border-box;
        
        .service{
            display: flex;
            align-items: center;
            position: absolute;
            right: 0.6rem;
            bottom: .3rem;
            z-index: 99;
          }
        .user-remark{
          position: absolute;
          left: 2rem;
          bottom: .3rem;
          z-index: 99;
        }
        .img-box {
          .wh(1.76rem, 1.76rem);
          margin-right: .16rem;
          background-color: #fff;
          img {
            display: block;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
          }
        }
        .img-right {
          max-width: 4.66rem;
          .item-name {
            .ellipsis(2);
            .sc(@fontsize-medium, @color-dark-grey);
            line-height: .4rem;
            font-weight: bold;
            word-wrap: break-word;
            word-break: break-all;
          }
        }
      }
    }
    #tkwl {
      margin-bottom: 0.3rem;
      padding: 0.3rem;
      color:#000;
     p {
       margin-top: 0.2rem;
      }
    }
    .material-box {
      box-sizing: border-box;
      background: #fff;
      padding: 0 0.18rem;
      box-sizing: border-box;
      
      .material-title {
        height: 1.08rem;
        display: flex;
        align-items: center;
        font-size: 0.26rem;
        color: #333333;
        border-bottom: 1px solid #e1e1e1;
        margin-bottom: 0.5rem;
      }

      .material-line {
        margin-bottom: 0.7rem;
        display: flex;
        align-items: center;
        color: #999999;

        .time {
          width: 0.8rem;
          margin-right: 0.66rem;

          p {
            text-align: center;
            white-space: nowrap;
          }

          p:nth-child(1) {
            font-size: 0.28rem;
            line-height: 0.4rem;
          }

          p:nth-child(2) {
            font-size: 0.22rem;
            line-height: 0.32rem;
          }
        }

        .info {
          p:nth-child(1) {
            font-size: 0.28rem;
            line-height: 0.4rem;
          }

          p:nth-child(2) {
            font-size: 0.24rem;
            line-height: 0.34rem;
          }
        }
      }

      .material-line:nth-child(1) {
        color: #333333;
      }
    }

    .flow-empty {
      flex-direction: column;
      .wh(6.9rem, 6.92rem);
      background-color: #fff;
      box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.05);
      border-radius: 4px;
      .img-box {
        .wh(3.64rem, 2.58rem);
        margin-bottom: .32rem;
        img {
          .wh(100%, 100%);
        }
      }
      .tip {
        .sc(@fontsize-medium, @color-dark-grey);
        line-height: .4rem;
      }
    }
  }
  
  .bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1rem;
    background-color: #fff;
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.1);
    z-index: 999;
    .collect-btn {
      // .ct;
      float: right;
      margin-top: 2.5%;
      margin-right: .3rem;
      .wh(2rem, .68rem);
      .sc(@fontsize-medium, @color-white);
      line-height: .68rem;
      text-align: center;
      white-space: nowrap;
      background-color: #FF4C4C;
      border-radius: .45rem;
    }
  }
}
</style>