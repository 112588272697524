var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show_fill,
          expression: "show_fill",
        },
      ],
      staticClass: "fill-logistics",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          if ($event.target !== $event.currentTarget) return null
          return _vm.cancel.apply(null, arguments)
        },
        scroll: function ($event) {
          $event.preventDefault()
        },
        touchmove: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c(
        "section",
        { staticClass: "fill-container df fdc jc-sb alc" },
        [
          _c("P", { staticClass: "title" }, [_vm._v("填写物流信息")]),
          _c(
            "div",
            { staticClass: "content-box" },
            [
              _c("yd-cell-item", { attrs: { arrow: "", type: "label" } }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.express_company_id,
                        expression: "express_company_id",
                      },
                    ],
                    class: { "font-select": _vm.express_company_id },
                    attrs: { slot: "right" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.express_company_id = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                    slot: "right",
                  },
                  [
                    _c(
                      "option",
                      {
                        staticStyle: { color: "#999999" },
                        attrs: { value: "" },
                      },
                      [_vm._v("请选择物流公司")]
                    ),
                    _vm._l(_vm.company_list, function (item, index) {
                      return _c(
                        "option",
                        {
                          key: index,
                          staticStyle: { color: "#333" },
                          domProps: { value: item.id },
                        },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c("yd-input", {
                attrs: {
                  slot: "right",
                  max: "20",
                  placeholder: "请输入退货物流单号",
                },
                slot: "right",
                model: {
                  value: _vm.express_number,
                  callback: function ($$v) {
                    _vm.express_number = $$v
                  },
                  expression: "express_number",
                },
              }),
              _c("yd-input", {
                attrs: {
                  slot: "right",
                  regex: "mobile",
                  placeholder: "请输入寄回的用户手机号",
                },
                slot: "right",
                model: {
                  value: _vm.express_mobile,
                  callback: function ($$v) {
                    _vm.express_mobile = $$v
                  },
                  expression: "express_mobile",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "btn",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.submitFill.apply(null, arguments)
                },
              },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }