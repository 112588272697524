var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _vm._m(0),
      _c("p", { staticClass: "tip" }, [_vm._v("您的商品已兑换成功")]),
      _c(
        "router-link",
        { staticClass: "gray-tip", attrs: { to: "check_order", replace: "" } },
        [_vm._v("查看订单>>")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img-box" }, [
      _c("img", {
        attrs: { src: require("@/static/image/ic_xuanze_big.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }