import fetch from "../utils/fetch";

//// 卡册商城模块
////// 1、卡册商城商品列表
export const getItemListCard = (page, listRows) =>
  fetch("/api", {
    api_name: "V1.Order.CardBook.getItemListCard",
    page: page,
    listRows: listRows
  });

////// 2、卡册商城验证卡号和密码
export const checkTicket = (params) =>
  fetch("/api", {
    api_name: "V1.Order.CardBook.checkTicket",
    ...params
  });
////// 2、卡册商城验证卡号和密码
export const checkTicketByMobile = (params) =>
  fetch("/api", {
    api_name: "V1.Order.CardBook.checkTicketByMobile",
    ...params
  });

////// 3、卡册商城下单
export const createCardOrder = params =>
  fetch(
    "/api",
    Object.assign(
      {},
      {
        api_name: "V1.Order.CardBook.createCardOrder"
      },
      params
    )
  );

////// 4、获取兑换规则信息
export const getCardRule = () =>
  fetch("/api", {
    api_name: "V1.Order.CardBook.getCardRule"
  });

////// 5、获取兑换配置信息
export const getConfig = () =>
  fetch("/api", {
    api_name: "V1.Order.CardBook.getConfig"
  });

////// 6、卡册商城获取图片地址
export const getImgs = () =>
  fetch("/api", {
    api_name: "V1.Order.CardBook.getImgs"
  });

////// 7、根据卡号和密码获取订单列表
export const getOrderList = params =>
  fetch("/api", {
    api_name: "V1.Order.CardBook.getOrderList",
    ...params
  });


////// 8、卡册商城下单前判断商家额度
export const checkItemCredit = (ids, bid) =>
  fetch("/api", {
    api_name: "V1.Order.CardBook.checkItemCredit",
    ids,
    bid
  });

////// 9、根据商品id获取不发货时间
export const getDeliveryByItems = params =>
  fetch("/api", {
    api_name: "V1.Order.CardBook.getDeliveryByItems",
    ...params
  });

////// 中行点击
export const zHbocClick = params =>
fetch("/api", {
  api_name: "V1.User.Index.bocClick",
  ...params
});
////// 中行获取
export const zHbocClickInfo = params =>
  fetch("/api", {
    api_name: "V1.User.Index.bocClickInfo",
    ...params
  });