<template>
  <div class="fill-logistics" @click.stop.self="cancel" v-show="show_fill" @scroll.prevent @touchmove.prevent>
    <section class="fill-container df fdc jc-sb alc">
      <P class="title">填写物流信息</P>
      <div class="content-box">
        <yd-cell-item arrow type="label">
          <select v-model="express_company_id" :class="{'font-select':express_company_id}" slot="right">
            <option value="" style="color: #999999;">请选择物流公司</option>
            <option style="color: #333;" :value="item.id" v-for="(item,index) in company_list" :key="index">{{item.name}}</option>
          </select>
        </yd-cell-item>
        <yd-input slot="right" max="20" v-model="express_number" placeholder="请输入退货物流单号"></yd-input>
        <!-- <yd-input slot="right" max="5" v-model="item_nums" placeholder="请输入退货数量"></yd-input> -->
        <yd-input slot="right" regex="mobile" v-model="express_mobile" placeholder="请输入寄回的用户手机号"></yd-input>
      </div>
      <div class="btn" @click.stop="submitFill">提交</div>
    </section>
  </div>
</template>

<script>
import {shippingCompany} from "@/services/orderApi.js";

export default {
  name: "FillLogistics",
  data() {
    return {
      show_fill: false,
      express_company_id: '',//物流公司id
      express_number: '',//单号
      item_nums: '',//退换货数量
      express_mobile:'',//寄回的用户手机号
      company_list:'',
    };
  },
  props: {
    value: Boolean,
    id: '',
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    async shippingCompany(){
      try {
        const res = await shippingCompany();
        if (res.code === 0) {
          const data = res.data;
          this.company_list=data
        }
      }catch (e) {

      }
    },
    //提交
    submitFill() {
      let params = {
        id:this.id,
        express_company_id: this.express_company_id,
        express_number: this.express_number,
        // item_nums: this.item_nums,
        express_mobile: this.express_mobile,
      };
      const regMobile = /^1[3|4|5|6|7|8|9]\d{9}$/;
      if (!params.express_company_id) {
        this.$dialog.toast({
          mes: '请选择物流公司',
        });
        return
      }
      if (!params.express_number) {
        this.$dialog.toast({
          mes: '请输入退货物流单号',
        });
        return
      }
      // if (!params.item_nums) {
      //   this.$dialog.toast({
      //     mes: '请输入退货数量',
      //   });
      //   return
      // }
      if (!regMobile.test(params.express_mobile)) {
        this.$dialog.toast({
          mes: '请填写正确的手机号',
        });
        return
      }
      this.$emit('submitFill', params);
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.show_fill = val;
        if(val){
          this.shippingCompany()
        }
      },
      immediate: true
    }
  },
}
</script>

<style lang="less">
.fill-logistics {
  background: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1900;
  background: rgba(0, 0, 0, .5);
}

.fill-container {
  height: 7.2rem;
  width: 6.3rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  box-sizing: border-box;
  background: #fff;
  animation: tipMove .4s;
  border-radius: 10px;
  padding: 0.34rem .40rem .44rem .44rem;

  .title {
    font-size: 0.32rem;
    color: #333;
    line-height: .50rem;
    text-align: center;
    font-weight: 500;
    word-wrap: break-word;
    word-break: break-all;
  }

  .content-box {
    width: 100%;

    .yd-input {
      height: 0.92rem;
      border-bottom: 1px solid #F0F0F0;
      font-size: 0.32rem;
    }
    input::placeholder {
      color: #999;
    }


    .yd-cell-item {
      padding-left: 0;
      border-bottom: 1px solid #F0F0F0;
    }

    .yd-cell-right {
      height: 0.92rem;
    }

    .yd-cell-right select {
      margin-left: 0;
      font-size: 0.32rem;
      color: #999;
    }
    .font-select{
      color: #333 !important;
    }

    .yd-cell-item:not(:last-child):after {
      background-image: none;
    }
  }

  .btn {
    width: 100%;
    height: 0.72rem;
    border-radius: 36px;
    background: var(--main-color);
    color: #fff;
    line-height: 0.72rem;
    text-align: center;
    font-size: 0.28rem;
  }

}

</style>
