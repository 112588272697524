var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showAlert
    ? _c("div", { staticClass: "alert-container" }, [
        _c("section", { staticClass: "tip-text-container flex-center" }, [
          _c("img", {
            staticClass: "tip-icon",
            attrs: { src: _vm.iconTip, alt: "icon" },
          }),
          _c("p", { staticClass: "tip-text" }, [_vm._v(_vm._s(_vm.alertText))]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }