<template>
  <div class="new-address">
    <!--<yd-cell-group>
      <yd-cell-item>
        <span slot="left">收货人</span>
        <yd-input slot="right" v-model="real_name" required :show-success-icon="false" :show-error-icon="false"
                  :show-clear-icon="false" max="10" placeholder="请填写收货人姓名"></yd-input>
      </yd-cell-item>
      <yd-cell-item>
        <span slot="left">联系电话</span>
        <yd-input slot="right" v-model="mobile" required :show-success-icon="false" :show-error-icon="false"
                  regex="mobile" :show-clear-icon="false" placeholder="请填写联系电话号码"></yd-input>
      </yd-cell-item>
      &lt;!&ndash;省市区四级联动&ndash;&gt;
      <div class="area-box df fdr alc" @click.stop="showArea">
        <span slot="left">省市区</span>
        <div class="area-string df alc" v-if="area_string">{{area_string}}</div>
        <div class="no-area" v-else>请选择</div>
      </div>
      <yd-cell-item>
        <span slot="left">详细地址</span>
        <yd-input slot="right" v-model="address" required :show-success-icon="false" :show-error-icon="false"
                  :show-clear-icon="false" max="20" placeholder="请填写详细地址"></yd-input>
      </yd-cell-item>
    </yd-cell-group>-->
    <div class="cell-group">
      <div class="cell">
        <span class="form-label">收货人</span>
        <input type="text" v-model="real_name" placeholder="请填写收货人姓名" maxlength="10">
      </div>
      <div class="cell">
        <span class="form-label">联系电话</span>
        <input type="tel" v-model="mobile" placeholder="请填写联系电话号码" maxlength="11">
      </div>
      <!--省市区四级联动-->
      <div class="area-box df fdr alc" @click.stop="showArea">
        <span slot="left">省市区</span>
        <div class="area-string df alc" v-if="area_string">{{area_string}}</div>
        <div class="no-area" v-else>请选择</div>
      </div>
      <div class="cell">
        <span class="form-label">详细地址</span>
        <input type="text" v-model="address" placeholder="请填写详细地址" maxlength="20">
      </div>
      <div class="cell" v-if="is_show">
        <yd-cell-group>
          <yd-cell-item arrow>
              <span slot="left">发货日期</span>
              <yd-datetime type="date" :start-date="get_time"  class="datetime" v-model="date_time" slot="right"></yd-datetime>
          </yd-cell-item>
        </yd-cell-group>
      </div>
    </div>
    <selectAddress
        ref="address"
        :edit_date="edit_date"
        @confirmAddress="confirmAddress"
    >
    </selectAddress>
    <div v-if="time_list.length" class="dzx-time">
      <p>
        温馨提示：</br>
        因特殊原因,以下时间段暂时不可预约发货时间，请您另行选择，不便之处，敬请谅解!
      </p>
      <ul>
        <li v-for="(item,index) in time_list" :key="index">
          {{ item[0]/1000 | formatDateStdC }}~ {{ item[1]/1000| formatDateStdC }}
        </li>
      </ul>
    </div>
        <footer @click="submit" v-if="is_submit">
      <!-- <MyButton title="保存" :is_disabled=false></MyButton> -->
      <div class="btn save-btn">确定</div>
    </footer>
     <footer v-else>
      <!-- <MyButton title="保存" :is_disabled=false></MyButton> -->
      <div class="btn save-btn">确定</div>
    </footer>
  </div>
</template>

<script>
import Validator from "@/utils/validator";
import {
  getProvinceList,
  getCityList,
  getAreaList,
  getStreetList
} from "@/services/my";
import { createCardOrder,getDeliveryByItems } from "@/services/cardMallApi";
import selectAddress from '@/components/common/selectAddress'
import {mapState} from 'vuex';
import { formatDate } from '@/utils/common'

document.removeEventListener("touchmove", event => {
  event.preventDefault();
});

export default {
  name: "NewAddress",
  components: {
    selectAddress
  },
  data() {
    return {
      edit_id: '',
      item_ids: this.$route.query.item_ids, // 商品id
      real_name: '',//收货人
      mobile: '',//手机号
      province_id: '',
      city_id: '',
      area_id: '',
      street_id: '',
      area_string: '',//省市区街道
      address: '',
      district: [],
      is_default: '',//是否是默认地址
      select_list: [],
      edit_date: '',//回显地址
      is_submit: true,
      date_time:'',//发货时间
      get_time:'',
      dzx_id:[410,2477,1582,412,679,484],
      is_show:false, //是否是特殊商城
      time_list:[]
    }
  },
  computed: {
    ...mapState({
      card_sn: state => state.card_data.card_sn,
      card_pass: state => state.card_data.card_pass,
      card_type: state => state.card_data.type,
      bid: state => state.businessInfo.id
    })
  },
  created() {
    const d = new Date().getTime() + 24 * 60 * 60*1000;
    //发货预约时间最早为下单后一天
    const t = new Date(d)
    this.get_time =t.getFullYear() + '-' + (t.getMonth() + 1) + '-' + t.getDate();
   
  },
  mounted() {
    for(let i in this.dzx_id){
      if(this.bid == this.dzx_id[i]){
        this.is_show = true
        this.getDeliveryByItems()
      }
    }
  },
  methods: {
    showArea() {
      this.$refs.address.choseAdd();
    },
    async getDeliveryByItems(){
        const res = await getDeliveryByItems({id:this.item_ids})
        if(res.code == 0){
          let list = [] 
          //  二维数组转一维数组
          res.data.map(item=>{
            list.push(item.toString())
          })
          //去重后一维数组转二维数组后排序
          let data = [...new Set(list)].map(item=>{return item.split(',')})
          data.sort((a,b)=>{return a[0]>b[0]?1:-1}) 
          this.time_list = data
        }
    },
    confirmAddress(params) {
      console.log(params);
      this.province_id = params.province_id
      this.city_id = params.city_id
      this.area_id = params.area_id
      this.street_id = params.street_id
      this.area_string = params.area_string
    },
    // 提交表单
    async submit() {
      console.log(this.card_type,"card_type")
      let user_remark = new Date(this.date_time).getTime()
      const list = this.time_list
      try {
        let param = {
          card_sn: this.card_sn,
          card_pass: this.card_type==2?'0':this.card_pass,
          ids: this.item_ids,
          realname: this.real_name,
          mobile: this.mobile,
          province_id: this.province_id,
          city_id: this.city_id,
          area_id: this.area_id,
          street_id: this.street_id,
          address: this.address,
          user_remark: this.date_time
        };
        const {result, errorMessage} = Validator.validate("mobile", param.mobile);
        if (!param.realname) {
          this.$dialog.toast({
            mes: '请填写收货人姓名',
          });
          return;
        }
        if (!param.mobile) {
          this.$dialog.toast({
            mes: '请填写联系电话号码',
          });
          return;
        }
        if (!result) {
          this.$dialog.toast({
            mes: errorMessage,
          });
          return;
        }
        if (!param.province_id) {
          this.$dialog.toast({
            mes: '请选择省市区',
            timeout: 1500
          });
          return
        }
        if (!param.address) {
          this.$dialog.toast({
            mes: '请填写详细地址',
            timeout: 1500
          });
          return
        }
        if (this.is_show) {
          if(user_remark){
            for(let i in list){
              if(user_remark>=list[i][0] && user_remark<=list[i][1]){
                this.$dialog.toast({
                  mes: '该日期暂不发货，请另行选择！',
                });
                return
              }
            }
          }else{
            this.$dialog.toast({
              mes: '请选择发货时间',
              timeout: 1500
            });
            return
          }
        }
        if(this.is_submit){
           this.is_submit = false
              const res = await createCardOrder(param);
              if (res.code === 0) {
                this.$router.replace({
                  path: '/exchange_success'
                });
              }else{
                this.$dialog.toast({
                  mes: res.msg
                });
                setTimeout(()=>{
                  this.is_submit = true
                },2000)
              }
        }
      } catch (error) {
        console.warn("Something bad happened: ", error);
      }

    },
  },
}
</script>

<style lang="less">
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

.new-address {
  .dzx-time{
    background: #fff;
    padding: 10px;
    line-height: 0.42rem;
    font-size: .28rem;
    color: #666;
    margin-top: 2px;
    p{
      color: #FF4C4C;
      padding-bottom: 12px;
    }
  }
  .cell-group{
    width: 100%;
    padding-left: 0.24rem;
    background: #fff;
    .cell {
      .wh(100%, 0.96rem);
      line-height: 0.96rem;
      border-bottom: 1px solid #D8D8D8;
      overflow: hidden;
      span{
        .sc(0.32rem, #333);
        width: 22%;
        display: inline-block;
      }
      input{
        width: 78%;
        height: 0.96rem;
        .sc(0.32rem, #666);
        &::placeholder {
          .sc(0.32rem, @color-light-grey);
        }
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  .yd-cell-item{
    padding: 0;
  }
  .datetime{
    font-size: 0.32rem;
    color: #999;
  }
  .yd-cell-arrow:after{
    content: "\E608";
    font-size: 0.32rem;
    color: #7d7d7d;
  }

  .yd-cell-left {
    .sc(0.32rem, @color-dark-grey);
    width: 19.1%;
    margin-right: 0.24rem;
    span{
      line-height:0.96rem;
    }
  }

  .yd-cell-right {
    height: 0.96rem;
    width: 80.9%;

    input {
      .sc(0.32rem, @color-grey);
      line-height: 100% !important;
      height: 100%;

      &::placeholder {
        .sc(0.32rem, @color-light-grey);
      }
    }
  }
  .yd-cell-right input[type=text]{
    height: 0.96rem;
    line-height: 0.96rem !important;
  }

  .yd-cell:after {
    height: 0;
  }

  /*设为默认*/

  .select-box {
    input {
      color: var(--main-color) !important;
      width: 1.02rem;
      height: 0.62rem;
      border-radius: 0.31rem;
    }

    .yd-switch:after {
      .wh(0.56rem, 0.56rem)
    }
  }

  .area-box {
    .sc(0.32rem, @color-dark-grey);
    min-height: 0.96rem;
    padding-right: 0.56rem;
    background: white url(../../assets/ic_arrow_right_gray@2x.png) no-repeat 97% 50%;
    background-size: 0.26rem 0.26rem;
    border-bottom: 1px solid #ddd;

    span {
      width: 23.5%;
    }

    .area-string, no-area {
      height: 100%;
      width: 79.2%;
      background: @color-white;
      border: none;
      line-height: 0.44rem;
      color: #666;
    }

    .no-area {
      color: @color-light-grey;
    }
  }


  .yd-cell-box {
    margin-bottom: 0.24rem;
  }

  footer {
    margin-top: 0.6rem;
    padding-left: .32rem;
    padding-right: .32rem;
    .save-btn {
      .wh(100%, .88rem);
      .sc(@fontsize-large, @color-white);
      line-height: .88rem;
      text-align: center;
      white-space: nowrap;
      background-color: #FF4C4C;
      border-radius: 4px;
    }
  }
}

</style>
