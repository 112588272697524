/*
 * @Author: zwb
 * @Date: 2019-06-10
 * @Last Modified by: zwb
 * @Last Modified time: 2019-06-10
 */
 import {customAccount} from '@/utils/custom';
if (localStorage.businessInfo && JSON.parse(localStorage.businessInfo).id != 2265 && JSON.parse(localStorage.businessInfo).id != 3668 && !customAccount("VUE_APP_CHAOWEI")) {
  (function (w, d, n, a, j) {
    w[n] = w[n] || function () {
      (w[n].a = w[n].a || []).push(arguments)
    };
    j = d.createElement('script');
    j.async = true;
    if (
      process.env.NODE_ENV === "production" ||
      process.env.NODE_ENV === "release" ||
      process.env.NODE_ENV === "prod"
    ) {
      j.src = 'https://qiyukf.com/script/56707a9838f363bb0f94c818d034dfae.js?hidden=1';
    } else {
      j.src = 'https://qiyukf.com/script/56707a9838f363bb0f94c818d034dfae.js?hidden=1';
    }
    d.body.appendChild(j);
  })(window, document, 'ysf');
}
