<template>
  <div class="alert-container" @click.stop.self="cancel" v-show="show" @scroll.prevent @touchmove.prevent>
    <section class="confirm-container">
      <P class="confirm-text" v-html="confirmText"></P>
      <div class="btn-group">
        <div class="btn cancel-btn flex-center" @click.stop="cancel">{{cancelBtnText}}</div>
        <div v-if="!is_delete" class="btn confirm-btn flex-center" @click.stop="confirm">{{confirmBtnText}}</div>
        <div v-if="is_delete" class="btn delete-btn flex-center" @click.stop="confirm">{{confirmBtnText}}</div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ConfirmAlert",
  data() {
    return {
      show: false
    }
  },
  props: {
    value: Boolean,
    is_delete: {type: Boolean, default: false},
    'confirmText': {type: String, default: ''},
    'cancelBtnText': {type: String, default: '取消'},
    'confirmBtnText': {type: String, default: '确定'}
  },
  methods: {
    // 点击取消按钮
    cancel() {
      this.$emit('cancel');
    },
    // 点击确认按钮
    confirm() {
      this.$emit('confirm');
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.show = val;
      },
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

@keyframes tipMove {
  0% {
    transform: scale(1)
  }
  35% {
    transform: scale(.8)
  }
  70% {
    transform: scale(1.1)
  }
  100% {
    transform: scale(1)
  }
}

.alert-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9900;
  background: rgba(0, 0, 0, .5);
}

.confirm-container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1.58rem;
  margin-left: -2.31rem;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  -webkit-align-items: center;
  width: 4.62rem;
  //height: 3.16rem;
  padding: .36rem .28rem .30rem;
  box-sizing: border-box;
  background: #fff;
  animation: tipMove .4s;
  border-radius: 4px;
}

.confirm-container .confirm-text {
  font-size: .28rem;
  color: #333;
  line-height: .50rem;
  text-align: center;
  font-weight: 500;
  word-wrap: break-word;
  word-break: break-all;
}

.confirm-container .btn-group {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  width: 100%;
  margin-top: 0.1rem;
}

.confirm-container .btn-group .btn {
  width: 1.90rem;
  height: .72rem;
  border-radius: 18px;
  font-size: .28rem;
  line-height: .40rem;
  font-weight: 500;
  text-align: center;
}

.confirm-container .btn-group .cancel-btn {
  border: 1px solid #333;
  color: #333;
}

.confirm-container .btn-group .confirm-btn {
  background: var(--main-color);
  color: #fff;
}

.confirm-container .btn-group .delete-btn {
  background: @color-add-red;
  color: #fff;
}

</style>
